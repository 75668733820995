import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Angle_left from "../../assets/images/angle_left.svg";
import Bed_Icon from "../../assets/images/bed_icon.svg";
import Beds_Icon from "../../assets/images/beds_icon.svg";
import FillHeart from '../../assets/svg/FillHeart';
import UnfillHeart from '../../assets/svg/UnfillHeart';
import PageLoader from '../../components/common/loader/PageLoader';
import DetailImageSlider from '../../components/common/slider/DetailImageSlider';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { formHandler } from '../../redux/slices/auth_slice/authentication';
import { customerFavoriteRequest, customerRemoveFavoriteRequest, getHomesById } from '../../redux/slices/home_slice/recovery_homes';
import "./home_details.css";


const HomeDetails = () => {
    const { id } = useParams()

    //
    const navigate = useNavigate()

    const [isHomeFavorite, setIsHomeFavorite] = useState<boolean>()



    // Redux Hooks 
    const dispatch = useAppDispatch();
    const { homeDetails, isLoading } = useAppSelector((state) => state.recoveryHomesSlice);


    //get Token from LocalStorage
    let token = localStorage.getItem("token") as unknown as boolean

    useEffect(() => {
        if (id) {
            dispatch(getHomesById(id))
        }
    }, [id])

    const handleEmailClick = (mail:string) => {
        const mailtoLink = `mailto:${mail}`;
         window.open(mailtoLink, '_blank');
      };

    useEffect(() => {
        if (homeDetails) {
            setIsHomeFavorite(homeDetails?.isLike)
        }

    }, [homeDetails])


    const contactNowHandler = () => {
        dispatch(formHandler(false))
        navigate("/")
    }


    function applyHandler(applyUrl: string) {
        if (!applyUrl) return
        const checkFormate = applyUrl.endsWith("@gmail.com")
        let url;
        if (checkFormate) {
            url = `https://${applyUrl}`;
        } else {
            url = applyUrl
        }
        window.open(url, '_blank');
    }

    const likeHandler = (homeId: string, isLike: boolean) => {

        setIsHomeFavorite(!isHomeFavorite)
        if (isHomeFavorite) {
            dispatch(customerRemoveFavoriteRequest(homeId))
        } else {
            dispatch(customerFavoriteRequest(homeId))
        }


    };

    return (
        <section id="home-details-main">
            {
                isLoading ? <PageLoader /> : (
                    <>
                        <div className="container">
                            <div className="row">
                                <div className="home-head-col">
                                    <div className="home-head">
                                        <Link to="/home" className="back-btn"><img src={Angle_left} alt="" /> back</Link>
                                        <div className="head-details">
                                            <div className="head-left">
                                                <h2>{homeDetails?.guestHouseName}</h2>
                                                <p>{homeDetails?.locationAddress?.text}</p>
                                            </div>
                                            <button disabled={!token} onClick={() => likeHandler(id as string, homeDetails?.isLike as boolean)}>{isHomeFavorite ? <FillHeart /> : <UnfillHeart />}Favorite</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="home-left-col">
                                    <div className="home-left-main">
                                        <div className="home-img-slide">
                                            <div>
                                                <div className="home-img">
                                                    <DetailImageSlider data={homeDetails?.images} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="home-bad-details">
                                            <div className="detail total-bed">
                                                <h5>Total Beds</h5>
                                                <span><img src={Bed_Icon} alt="" />{homeDetails?.totalBeds}</span>
                                            </div>
                                            <div className="detail">
                                                <h5>Beds Available</h5>
                                                <span><img src={Beds_Icon} alt="" />{homeDetails?.bedsAvailable}</span>
                                            </div>
                                            <div className="detail">
                                                <h5>Enrollment Fee</h5>
                                                <span>${homeDetails?.enrollmentFees}</span>
                                            </div>
                                        </div>
                                        <h3>About this home</h3>
                                        <p>{homeDetails?.description}</p>
                                        <div className="additional-info">
                                            <h3>Additional Information</h3>
                                            <ul>
                                                <li>Level <span>{homeDetails?.level}</span></li>
                                                <li>Payment Type <span>{homeDetails?.paymentType?.join(",")}</span></li>
                                                <li>MAT (Medication-assisted treatment) <span>{homeDetails?.medicationAssistedTreatment}</span></li>
                                                <li>Transportation Options <span>{homeDetails?.transportOptions?.join(",")}</span></li>
                                                <li>ADA Complaint <span>{homeDetails?.adaComplaint}</span></li>
                                                <li>Additional Details <span>{homeDetails?.additionalDetails?.join(",")}</span></li>
                                                <li>NARR Accredited or Certified <span>{homeDetails?.narrAccreditedOrCertified}</span></li>
                                                <li>Type of Structure <span>{homeDetails?.typeOfStructure}</span></li>
                                                <li>Gender<span>{homeDetails?.gender}</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="home-right-col">
                                    <div className="home-right-main">
                                        <div className="home-right-img active">
                                            <img src={homeDetails?.images[1] ? homeDetails?.images[1] : homeDetails?.images[0]} alt="Image Not Found " />
                                        </div>
                                        <div className="home-right-img">
                                            <img src={homeDetails?.images[2] ? homeDetails?.images[2] : homeDetails?.images[0]} alt="Image Not Found " />

                                        </div>
                                        <div className="home-right-details">
                                            <div className="price">
                                                <span>Rent price</span>
                                                <h2>${homeDetails?.pricePerBedWeekly} <span>/ week</span></h2>
                                            </div>
                                            {
                                                token ? (
                                                    <>
                                                        <div className="contact-info">
                                                            <h4>Contact Information</h4>
                                                            <ul>
                                                                <li>Mobile Number <span>{homeDetails?.contactInfo?.phoneNumber?.dialCode}{" "}{homeDetails?.contactInfo?.phoneNumber?.number}</span></li>
                                                                <li>Email Id <span onClick={()=>handleEmailClick(homeDetails?.contactInfo?.email as string)}> {homeDetails?.contactInfo?.email}</span></li>
                                                            </ul>
                                                            <div className="contact-btn">
                                                                <button onClick={() => applyHandler(homeDetails?.contactInfo?.url as string)} > Apply Now</button>
                                                            </div>

                                                        </div>
                                                    </>
                                                ) : <div className="contact-btn">
                                                    <button onClick={contactNowHandler}>Sign up for free</button>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }

        </section >
    );
}

export default HomeDetails;
