import { ChangeEvent, useState } from "react";
import Email from '../../assets/images/Email_icon.svg';
import LeftIcon from '../../assets/images/angle_left.svg';
import { useNavigate } from "react-router-dom";

const SupportPage = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
    });
    const [errors, setErrors] = useState({
        email: "",
        phone: "",
    });
    const navigate = useNavigate();
    const thanksAndRegards = 'Thanks and Regards';
    const { name, email, phone, subject, message } = formData;

    const validateEmail = (email: string) => {
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailPattern.test(email);
    };

    const validatePhone = (phone: string) => {
        const phonePattern = /^\d{10}$/;
        return phonePattern.test(phone);
    };
    const mailtoLink = `mailto:support@lighthouse.house?subject=${encodeURIComponent(
        subject
    )}&body=${encodeURIComponent(
        `${message}\n\nName: ${name}\nEmail: ${email}\nPhone: ${phone}\n\n${thanksAndRegards}`
    )}`;

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (name === "phone") {
            const numericValue = value.replace(/\D/g, '');
            if (numericValue.length <= 10) {
                setFormData({
                    ...formData,
                    [name]: numericValue,
                });
            }
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
        if (name === "email") {
            if (!validateEmail(value)) {
                setErrors({
                    ...errors,
                    email: "Invalid email format",
                });
            } else {
                setErrors({
                    ...errors,
                    email: "",
                });
            }
        }
    };

    const handleMessageChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setFormData({
            ...formData,
            message: e.target.value,
        });
    };

    const handleSendEmail = () => {
        if (!validateEmail(formData.email)) {
            setErrors({
                ...errors,
                email: "Invalid email format",
            });
            return;
        }
        if (!validatePhone(formData.phone)) {
            setErrors({
                ...errors,
                phone: "Invalid phone number format",
            });
            return;
        }

        // window.location.href = mailtoLink;
        window.open(mailtoLink, '_blank');
    };

    const handleBack = () =>{
        navigate(-1)
    }

    return (
        <section id="contact-main">
            <div className="container">
                <div className="back-btn" onClick={handleBack}>
                    <button><img src={LeftIcon} alt="" />Back</button>
                </div>
                <div className="contact-form">
                    <div className="details">
                        <h2>Need Help or Have Questions?</h2>
                        <p>
                            Contact our support team for assistance with any queries or
                            concerns regarding renting homes. Reach us via email. We're here
                            to help make your experience seamless.
                        </p>
                        <ul>
                            <li>
                                <span><img src={Email} alt="" /></span>
                                <span><b>Email:</b> <a href="">support@lighthouse.house</a>  </span>
                            </li>
                        </ul>
                        <p>
                            Lighthouse Collective Foundation, Inc. 501(c) (3) Nonprofit Public
                            Charity EIN 92-2429737
                        </p>
                    </div>
                    <form action="">
                        <h3>How can we help you?</h3>
                        <div className="input-field">
                            <input
                                type="text"
                                name="name"
                                placeholder="Your name"
                                value={name}
                                required={true}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="input-field">
                            <input
                                type="email"
                                name="email"
                                placeholder="Email address"
                                value={email}
                                required={true}
                                onChange={handleInputChange}
                            />
                            <p style={{color:"red",fontSize:"15px",marginTop:'10px'}}>{errors?.email}</p>
                        </div>
                        <div className="input-field">
                            <input
                                type="text"
                                name="phone"
                                placeholder="Phone number"
                                value={phone}
                                required={true}
                                onChange={handleInputChange}
                            />
                            <p style={{color:"red",fontSize:"15px",marginTop:'10px'}}>{errors?.phone}</p>
                        </div>
                        <div className="input-field">
                            <input
                                type="text"
                                name="subject"
                                placeholder="Subject"
                                value={subject}
                                required={true}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="input-field">
                            <textarea
                                name="message"
                                placeholder="Type something..."
                                value={message}
                                required={true}
                                onChange={handleMessageChange}
                            ></textarea>
                        </div>
                        <button onClick={handleSendEmail} >Send Message</button>
                    </form>
                </div>
            </div>
        </section>
    );
}

export default SupportPage;
