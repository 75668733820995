import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addFavorite,  getCustomerHomeInfoById, getFilters, getHomeInfoById, likeRequestRequestData, recoveryHomeRequest, recoveryHomes, removeFavorite } from "../../../config/makeRequests/homeRequests";
import { FilterDropdownData, RecoveryHomeState, homesDataTypes } from "../../../types/homeTypes";
import { errorAlert, successAlert } from "../../../utils/toasts";
import { GetRecoveryHomes } from "../../types/recover_homes";





export const getRecoveryHomes = createAsyncThunk(
    "getRecoveryHomes/recoveryHomesSlice",
    async (data: GetRecoveryHomes) => {
        try {
            const userId = localStorage.getItem("userID") as string
            const requestPayload = {
                ...data, userId
            }
            // below condition of two api call one is authorization or another is without authorization
            let res;
            if (userId) {
                res = await recoveryHomes(requestPayload)
            } else {
                res = await recoveryHomeRequest(requestPayload)
              
            }
            if (res.status === true) {
                return res?.data
            }

        } catch (error: any) {
            const { message } = error?.response?.data
            errorAlert(message)
            console.log("error while getRecoveryHomes", error);
        }
    }
);

export const customerFavoriteRequest = createAsyncThunk(
    "customerFavoriteRequest/recoveryHomesSlice",
    async (homeId: string) => {
        try {
            const userId = localStorage.getItem("userID") as string
            const data: likeRequestRequestData = {
                homeId,
                userId
            }

            let res = await addFavorite(data)
            if (res.status === true) {
                // successAlert(res?.message)
                return res
            }

        } catch (error: any) {
            const { message } = error?.response?.data
            const msg = error?.response?.data
            errorAlert(message || msg)
            console.log("error while customerHomesLikeRequest", error);
        }
    }
);
export const customerRemoveFavoriteRequest = createAsyncThunk(
    "customerRemoveFavoriteRequest/recoveryHomesSlice",
    async (homeId: string) => {
        try {
            const userId = localStorage.getItem("userID") as string

            let res: any = await removeFavorite(userId, homeId)
            if (res?.status === true) {
                // successAlert("Status change successfully")
            }

        } catch (error: any) {
            const { message } = error?.response?.data
            const msg = error?.response?.data
            errorAlert(message || msg)
            console.log("error while customerHomesLikeRequest", error);
        }
    }
);

export const getFiltersvalues = createAsyncThunk(
    "getFiltersvalues/recoveryHomesSlice",
    async () => {
        try {
            let res = await getFilters()
            if (res.status === true) {
                return res
            }

        } catch (error: any) {
            const { message } = error?.response?.data
            errorAlert(message)
            console.log("error while get getFiltersvalues", error);
        }
    }
);


export const getHomesById = createAsyncThunk(
    "getHomesById/recoveryHomesSlice",
    async (id: string) => {
        try {
            const userId = localStorage.getItem("userID") as string
             // below condition of two api call one is authorization or another is without authorization
            let res;
            if (userId) {
                res = await getCustomerHomeInfoById({ id, userId })
            } else {
                res = await getHomeInfoById({ id, userId })
            }
            if (res.status == true) {
                return res
            }
        } catch (error: any) {
            const { message } = error?.response?.data
            errorAlert(message)
            console.log("error while getHomesById ", error);
        }
    }
);



interface StateType {
    data: RecoveryHomeState | null | undefined;
    isLoading: boolean;
    likeRequestLoader: boolean;
    filtersData: FilterDropdownData | null | undefined;
    filterLoader: boolean;
    homeDetails: homesDataTypes | null | undefined;
   
}


const initialState: StateType = {
    data: null,
    isLoading: false,
    likeRequestLoader: false,
    filtersData: null,
    filterLoader: false,
    homeDetails: null,
  
};

const recoveryHomesSlice = createSlice({
    name: "recoveryHomesSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getRecoveryHomes.pending, (state: StateType) => {
            state.isLoading = true;
        }).addCase(getRecoveryHomes.fulfilled, (state: StateType, { payload }) => {
            state.data = payload
            state.isLoading = false;
        }).addCase(getRecoveryHomes.rejected, (state: StateType, { payload }) => {
            state.isLoading = false
        }).addCase(customerFavoriteRequest.pending, (state: StateType, { payload }) => {

            state.likeRequestLoader = true
        }).addCase(customerFavoriteRequest.fulfilled, (state: StateType, { payload }) => {
            state.likeRequestLoader = false

        }).addCase(customerFavoriteRequest.rejected, (state: StateType, { payload }) => {
            state.likeRequestLoader = false

        }).addCase(customerRemoveFavoriteRequest.pending, (state: StateType, { payload }) => {
            state.likeRequestLoader = true

        }).addCase(customerRemoveFavoriteRequest.fulfilled, (state: StateType, { payload }) => {
            state.likeRequestLoader = false

        }).addCase(customerRemoveFavoriteRequest.rejected, (state: StateType, { payload }) => {
            state.likeRequestLoader = false

        })
            .addCase(getFiltersvalues.pending, (state: StateType, { payload }) => {
                state.filterLoader = true
            }).addCase(getFiltersvalues.fulfilled, (state: StateType, { payload }) => {
                state.filtersData = payload?.data
                state.filterLoader = false
            }).addCase(getFiltersvalues.rejected, (state: StateType, { payload }) => {
                state.filterLoader = false
            }).addCase(getHomesById.pending, (state) => {
                state.isLoading = true
            }).addCase(getHomesById.fulfilled, (state, { payload }) => {
                state.isLoading = false
                state.homeDetails = payload?.data

            }).addCase(getHomesById.rejected, (state, { payload }) => {
                state.isLoading = false

            })

    }
});


export default recoveryHomesSlice.reducer;
