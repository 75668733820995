
import "./verify_otp.css"
import Times_Icon from "../../../assets/images/times_icon.svg";
import { InitialState } from "../../auth/register/Register";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import Loader from "../loader/Loader";
import { useAppSelector } from "../../../hooks/reduxHooks";
interface PropType {
    data: any;
    setOtpVerification: React.Dispatch<React.SetStateAction<boolean>>;
    otpLoader: boolean;
    verifyHandler: (a: string) => void
    getNewOtp: () => void
}

const VerifyOtp = ({ data, setOtpVerification, otpLoader, verifyHandler, getNewOtp }: PropType) => {
    const [otpValue, setOtpValue] = useState(new Array(6).fill(""))
    const inputRef = useRef<Array<HTMLInputElement | null>>([]);

    const { resendOtpLoader } = useAppSelector((state) => state.authentication)

    useEffect(() => {
        if (inputRef.current[0]) {
            inputRef.current[0].focus();
        }
    }, []);

// Otp handler
function otpHandler(value: string, i: number) {
    const customerInput = value.substring(value.length -1)
    setOtpValue((prevArray) => {
        const newArray = [...prevArray];
        newArray[i] = customerInput;
        return newArray;
    });

    // move focus to the next input
    if (inputRef.current && i < otpValue.length - 1 && value !== "" && inputRef.current[i + 1]) {
        inputRef.current[i + 1]!.focus(); 
    }
}





    const otpSubmitHandler = () => {
        const otp = otpValue.join("")
        verifyHandler(otp)
    }

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement> , i:number)=>{

        if(e.key == "Backspace"){
            // move focus to the previous  input
            if(!otpValue[i] && i > 0 ){
                inputRef.current[i - 1]!.focus(); 
            }

        }

    }




    return (
        <>
            <div className="otp-Verification-overlay">
                <div className="otp-main">
                    <div className="head">
                        <h2>Verify Email</h2>
                        <button className="close-btn" onClick={() => setOtpVerification(false)}><img src={Times_Icon} alt="" /></button>
                    </div>
                    <div className="details">
                        <p>We have sent an OTP to <span className="verify-email">{data}</span></p>
                        <span>Please Enter OTP to continue.</span>
                        <div className="input-field">
                            {
                                otpValue?.map((_item: string, i: number) =>
                                    <input
                                        key={i} value={otpValue[i]}
                                        onChange={(event: ChangeEvent<HTMLInputElement>) => otpHandler(event.target.value, i)} 
                                        type="number"
                                        ref={(input) => {
                                            inputRef.current[i] = input
                                        }
                                        }
                                        onKeyDown={(e:React.KeyboardEvent<HTMLInputElement>) => handleKeyPress(e , i)}
                                    />)
                            }

                        </div>
                        <span className="resend">Didn’t receive an OTP? <button className="resend-btn" disabled={resendOtpLoader} onClick={getNewOtp}>{resendOtpLoader ? "loading..." : "Resend OTP"}</button></span>
                        <button className="verify-btn" onClick={otpSubmitHandler}> {otpLoader ? <Loader width={30} height={30} /> : "Verify OTP"}</button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default VerifyOtp;
