import React, { useState } from "react"
import mailIcon from "../../../assets/images/mail_icon.svg"
import "./forgotpassword.css";
import { LuEye, LuEyeOff } from "react-icons/lu";
import { forgotPassword, resendOtp, resetPassword, verifyCustomerOtp } from "../../../config/makeRequests/auth";
import Loader from "../../common/loader/Loader";
import VerifyOtp from "../../common/verifyOtp/VerifyOtp";

import {formHandler, resendOtpHandler, showForgotPasswordScreen} from "../../../redux/slices/auth_slice/authentication";
import { useAppDispatch } from "../../../hooks/reduxHooks";
import { errorAlert, successAlert } from "../../../utils/toasts";


const Forgotpassword = () => {

    const [email, setEmail] = useState("")
    const [btnValue, setBtnValue] = useState("Send OTP")
    const [isLoading, setIsLoading] = useState(false)
    const [isPasswordChange, setIsPasswordChange] = useState(false)
    const [otpVerification, setOtpVerification] = useState(false)
    const [otpLoader , setOtpLoader] = useState(false)

    const [passwordVisible, setPasswordVisible] = useState(false);
    const [newPasswordEyeIcon, setNewPasswordEyeIcon] = useState(false);

    const [passwordFields , setPasswordFields] = useState({
        newPassword:"",
        confirmPassword:"",
    })


     // Redux Hooks 
  const dispatch = useAppDispatch()

  const handleClickIcon = () => {
    setPasswordVisible(!passwordVisible)
}
const handleClickNewPasswordIcon = () => {
    setNewPasswordEyeIcon(!newPasswordEyeIcon)
}

const handleForgotPassword = async () => {
    if(!email) return;
    try {
        setIsLoading(true);
        const res = await forgotPassword({ email });
        if (res?.status) {
            setIsLoading(false);
            setOtpVerification(true);
        }
    } catch (error) {
        setIsLoading(false);
        if (error as any) {
            const { message, status } = (error as any)?.response?.data;
            if (!status) {
                errorAlert(message);
            }
            console.log("error", error);
        }
    }
};

  
    const getNewOtp = async () => {
        if (email) {
            dispatch(resendOtpHandler({ email }));
          
        }
      }

    const otpSubmitHandler = async (otp: string)=> {
        if(!otp) return;
  try{
    setOtpLoader(true)
    const res = await verifyCustomerOtp({email , otp})
    if(res?.status){
        successAlert(res?.message)
        setOtpVerification(false)
        setIsPasswordChange(true)
        setOtpLoader(false)
        setBtnValue("Submit")
    }
     }catch(error){
    setOtpLoader(false)
    if (error as any) {
        const { message, status } = (error as any)?.response?.data;
        if (!status) {
            errorAlert(message);
        }
        console.log("error", error);
    }
    console.log(error , "error")
}
 }

 const onChangeHandler = (e:any)=>{
    const {name , value} = e.target
    setPasswordFields({
        ...passwordFields , [name] : value
    })

 }

 const changePassword = async ()=>{

    const  {confirmPassword , newPassword}   = passwordFields;

    if (!confirmPassword || !newPassword) {
        errorAlert("Both fields are required!");
        return;
    }
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    if (!passwordRegex.test(newPassword)) {
        errorAlert("Confirm password must be at least 8 characters and include at least one uppercase letter, one lowercase letter, and one number!");
        return false;
    }
    if (confirmPassword !== newPassword) {
        errorAlert("Passwords do not match!");
        return;
    }
    try {
        setIsLoading(true)
        const data = {
            confirmPassword,
            newPassword,
            email,
        }
        
        const res = await resetPassword(data)
        if(res?.status){
            successAlert(res?.message)
            dispatch(showForgotPasswordScreen(false))
            dispatch(formHandler(true))
            setIsLoading(false)

        }

        
    } catch (error) {
        setIsLoading(false)
        console.log("error" , error)
    }
 }
  

 const btnHandler = ()=>{
    if(!isPasswordChange){
        handleForgotPassword()
    }else{
        changePassword()
    }
 }

    return (
        <>

            {otpVerification &&
                <VerifyOtp
                    data={email}
                    otpLoader={otpLoader}
                    setOtpVerification={setOtpVerification}
                    verifyHandler={otpSubmitHandler}
                    getNewOtp={getNewOtp}
                />}
            <div className="login-main forgot-input-main">
                <h2>Forgot Password</h2>
                {
                    !isPasswordChange ? <div className="input-field">
                        <div className="input-left">
                            {/* <label>Email</label> */}
                            <input
                                name="email"
                                type="email"
                                autoComplete="off"
                                placeholder="example@domain.com"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="input-right">
                            <span><img src={mailIcon} alt="" /></span>
                        </div>
                    </div> : (
                        <>
                            <div className="input-field">
                                <div className="input-left">
                                    <input className='password-place' value={passwordFields?.newPassword} onChange={onChangeHandler} name="newPassword" autoComplete="off"  type={passwordVisible ? 'text' : 'password'} placeholder="New Password" />
                                </div>
                                <div className="input-right" onClick={handleClickIcon}>
                                {passwordVisible ?  <LuEye size={25}  /> : <LuEyeOff size={25} /> }
                                </div>
                            </div>
                            <div className="input-field">
                                <div className="input-left">
                                    <input className='password-place' value={passwordFields?.confirmPassword} onChange={onChangeHandler} name="confirmPassword" autoComplete="off"  type={newPasswordEyeIcon ? 'text' : 'password'} placeholder="Confirm New Password" />
                                </div>
                                <div className="input-right" onClick={handleClickNewPasswordIcon}>
                                {newPasswordEyeIcon ?  <LuEye size={25}  /> :  <LuEyeOff size={25} />}
                                </div>
                            </div>
                        </>
                    )
                }


                <div className="login-btn-main forgot-btn">
                    <button className="login-btn" onClick={btnHandler}> {isLoading ? <Loader width={30} height={30} /> : btnValue} </button>
                </div>
            </div>

        </>
    )
}

export default Forgotpassword