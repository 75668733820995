import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./slider.css";
const DetailImageSlider = ({data}:{data:string[] | undefined}) => {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 720,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  initialSlide: 1
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
        ],
    };
  return (
    <div className="detailSlider">
        <Slider {...settings} arrows={false}>
       {data?.map((item:string , i:number) => {
                    return <img key={i} src={item} alt="Image Not Found"/>;
                  })}
    </Slider>
    </div>
  );
}

export default DetailImageSlider;
