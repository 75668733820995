import React from 'react';

const LeftArrow = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="15" viewBox="0 0 10 15" fill="none">
<path fillRule="evenodd" clipRule="evenodd" d="M0.956165 8.61186C0.664079 8.31941 0.500015 7.92298 0.500015 7.50965C0.500015 7.09632 0.664079 6.69988 0.956165 6.40743L6.8374 0.524119C7.13 0.231656 7.5268 0.0674074 7.9405 0.0675049C8.3542 0.0676024 8.75092 0.232038 9.04339 0.524639C9.33585 0.817239 9.5001 1.21404 9.5 1.62774C9.4999 2.04144 9.33547 2.43816 9.04287 2.73062L4.26384 7.50965L9.04287 12.2887C9.32713 12.5827 9.48453 12.9766 9.48117 13.3856C9.47781 13.7945 9.31396 14.1858 9.0249 14.4752C8.73585 14.7645 8.34473 14.9287 7.93577 14.9324C7.52681 14.9362 7.13274 14.7792 6.83844 14.4952L0.955124 8.6129L0.956165 8.61186Z" fill="black"/>
</svg>
  );
}

export default LeftArrow;
