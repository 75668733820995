import React from 'react';

const RightArrow = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="15" viewBox="0 0 10 15" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.04383 6.38814C9.33592 6.68059 9.49998 7.07702 9.49998 7.49035C9.49998 7.90368 9.33592 8.30012 9.04383 8.59257L3.1626 14.4759C2.87 14.7683 2.4732 14.9326 2.0595 14.9325C1.6458 14.9324 1.24908 14.768 0.956615 14.4754C0.664152 14.1828 0.499903 13.786 0.5 13.3723C0.500098 12.9586 0.664534 12.5618 0.957134 12.2694L5.73616 7.49035L0.957134 2.71133C0.672874 2.41729 0.515474 2.02338 0.518833 1.61442C0.522193 1.20545 0.686044 0.814174 0.975096 0.524849C1.26415 0.235524 1.65527 0.0713043 2.06423 0.0675583C2.47319 0.0638132 2.86726 0.220843 3.16156 0.504826L9.04488 6.3871L9.04383 6.38814Z" fill="black" />
        </svg>
    );
}

export default RightArrow;
