import React from 'react';
import "../terms_conditions/term_conditions.css";
import Angle_Left from "../../assets/images/angle_left.svg"
import Privacy from '../../links/Privacy';
const PrivacyPolicy = () => {
    return (
        <>
            <Privacy />
            {/* <section id="terms-main">
            <div className="footer-container">
                <div className="row">
                    <div className="terms-col">
                        <div className="terms-details">
                            <div className="back-btn">
                                <a href="#"><img src={Angle_Left} alt="" />Back</a>
                            </div>
                            <div className="our-term">
                                <h2>Privacy Policy</h2>
                                <p>Read our terms below to learn more about your rights and responsibilities as a
                                    Inspired Meadows user.</p>
                            </div>
                            <div className="terms-update">
                                <h3>Updated May 16, 2023</h3>
                                <p >At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium
                                    voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint
                                    occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt
                                    mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et
                                    expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque
                                    nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas
                                    assumenda est, omnis dolor repellendus. </p>
                                <p className="mb">Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe
                                    eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum
                                    rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias
                                    consequatur aut perferendis doloribus asperiores repellat.</p>
                                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
                                    laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi
                                    architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia
                                    voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos
                                    qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum
                                    quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi
                                    tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>
                            </div>
                            <div className="instruction">
                                <h4>PLEASE READ THESE TERMS CAREFULLY AND BE SURE YOU UNDERSTAND IT FULLY, BECAUSE IT
                                    EXPLAINS AND CONTROLS YOUR LEGAL RELATIONSHIP WITH US AND YOUR RIGHTS RELATED TO
                                    YOUR USE OF THE SERVICE.</h4>
                                <p>If you have any questions, please don’t hesitate to contact us using our Support Form
                                </p>
                            </div>
                            <div className="services-use">
                                <p className="service-first"><span className="terms-heading">Use of Our Service:</span> At vero eos et accusamus et iusto odio dignissimos
                                    ducimus qui
                                    blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas
                                    molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa
                                    qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem
                                    rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est
                                    eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere
                                    possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem
                                    quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et
                                    voluptates repudiandae sint et molestiae non recusandae.</p>
                                <ul>
                                    <li>
                                        <p className="mb"><span className="terms-heading">Eligibility:</span> Similique sunt in culpa qui
                                            officia deserunt mollitia animi, id
                                            est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita
                                            distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque
                                            nihil impedit quo minus id quod maxime placeat facere possimus, omnis
                                            voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam
                                            et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et
                                            voluptates repudiandae sint et molestiae non recusandae.</p>
                                    </li>
                                    <li>
                                        <p className="mb"><span className="terms-heading">License Terms:</span> Similique sunt in culpa qui
                                            officia deserunt mollitia animi,
                                            id est laborum et dolorum fuga. Et harum quidem rerum facilis est et
                                            expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio
                                            cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis
                                            voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam
                                            et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et
                                            voluptates repudiandae sint et molestiae non recusandae.</p>
                                    </li>
                                    <li>
                                        <p className="mb"><span className="terms-heading">Our Services:</span> Similique sunt in culpa qui
                                            officia deserunt mollitia animi, id
                                            est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita
                                            distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque
                                            nihil impedit quo minus id quod maxime placeat facere possimus, omnis
                                            voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam
                                            et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et
                                            voluptates repudiandae sint et molestiae non recusandae.</p>
                                    </li>
                                    <li>
                                        <p className="mb"><span className="terms-heading">Your Account:</span> Similique sunt in culpa qui
                                            officia deserunt mollitia animi, id
                                            est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita
                                            distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque
                                            nihil impedit quo minus id quod maxime placeat facere possimus, omnis
                                            voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam
                                            et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et
                                            voluptates repudiandae sint et molestiae non recusandae.</p>
                                    </li>
                                    <li>
                                        <p><span className="terms-heading">Monitoring and Enforcement, Termination:</span>
                                            Similique sunt in culpa qui officia
                                            deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem
                                            rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta
                                            nobis est eligendi optio cumque nihil impedit quo minus id quod maxime
                                            placeat facere possimus, omnis voluptas assumenda est, omnis dolor
                                            repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum
                                            necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae
                                            non recusandae.</p>
                                    </li>
                                </ul>
                            </div>
                            <div className="term-paragraph">
                                <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium
                                    voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint
                                    occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt
                                    mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et
                                    expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque
                                    nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas
                                    assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis
                                    debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et
                                    molestiae non recusandae.</p>

                                <p className="mb">On the other hand, we denounce with righteous indignation and dislike men who are so
                                    beguiled and demoralized by the charms of pleasure of the moment, so blinded by
                                    desire, that they cannot foresee the pain and trouble that are bound to ensue; and
                                    equal blame belongs to those who fail in their duty through weakness of will, which
                                    is the same as saying through shrinking from toil and pain. These cases are
                                    perfectly simple and easy to distinguish. In a free hour, when our power of choice
                                    is untrammelled and when nothing prevents our being able to do what we like best,
                                    every pleasure is to be welcomed and every pain avoided. But in certain
                                    circumstances and owing to the claims of duty or the obligations of business it will
                                    frequently occur that pleasures have to be repudiated and annoyances accepted. The
                                    wise man therefore always holds in these matters to this principle of selection: he
                                    rejects pleasures to secure other greater pleasures, or else he endures pains to
                                    avoid worse pains.</p>
                            </div>
                            <div className="term-contact">
                                <p><span className="terms-heading">Contact:</span> Please visit our Help Center with any questions regarding the Services, or
                                    submit a support ticket with any questions regarding these Terms, Service, account,
                                    or billing matters. You may also contact us by email at help@inspiredmeadows.com</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}

        </>

    );
}

export default PrivacyPolicy;
