import React, { useEffect, useState } from 'react';
import "../home/home.css"
import HomeCard from '../../components/common/card/HomeCard';
import Angle_Left_Black from "../../assets/images/angle_left_black.svg"
import Angle_Right_Black from "../../assets/images/angle_right_black.svg"
import useWindowSize from '../../hooks/useWindowSize';
import SliderComponent from '../../components/common/slider/SliderComponent';
import { RecoveryHome } from '../../types/homeTypes';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { customerFavoriteRequest, customerRemoveFavoriteRequest, getRecoveryHomes } from '../../redux/slices/home_slice/recovery_homes';
import PageLoader from '../../components/common/loader/PageLoader';
import Pagination from '../../components/common/pagination/Pagination';
import { getLikedHomes } from '../../redux/slices/liked_home_slice/liked_home';
import Nohome from '../../components/common/no_homesfound_yet/No_home';

const Favourites = () => {

    // internal storage of component
    const [currentPage, setCurrentPage] = useState(1)
    const [favoriteHomes, setFavoriteHomes] = useState<any>([]);
    const [perPage, setPerPage] = useState<number>(12)


    // show Slider in mobile screen with the help of useWindowSize() hook
    const isMobile = useWindowSize()

    useEffect(() => {
        if (isMobile) {
          setPerPage(4)
        }else{
          setPerPage(12)
        }
      }, [isMobile])

    // Redux Hooks 
    const dispatch = useAppDispatch();
    const { data, isLoading, error } = useAppSelector((state) => state.likedHome);

    useEffect(() => {
        if (data) {
            setFavoriteHomes([...data?.recoveryHomes])
        }

    }, [data ])

    useEffect(() => {
        dispatch(getLikedHomes({ currentPage , perPage }))
    }, [currentPage , perPage])

    const pageHandler = (e: any) => {
        setCurrentPage(e.selected + 1)
    }

    const likeHandler = (event: React.MouseEvent<HTMLButtonElement>, homeId: string, isLike: boolean) => {
        event.stopPropagation();
        if (isLike) {
            dispatch(customerRemoveFavoriteRequest(homeId))
        } else {
            dispatch(customerFavoriteRequest(homeId))
        }

        setFavoriteHomes((prevRecoveryHomes: any) => {
            const newData = prevRecoveryHomes.filter((item: any) => {
                return item?.id != homeId
            })
            return newData
        });

    };


    return (
        <>
            <section id="search-recovery-main">
                {
                    isLoading ? <PageLoader /> : (
                        <>
                            {
                                favoriteHomes.length > 0 ? (<>
                                    <div className="container">
                                        <div className="row">
                                            <div className="recoveryhead-col">
                                                <div className="recoveryhead">
                                                    <h2>Favorites</h2>
                                                </div>
                                            </div>
                                            {favoriteHomes?.map((item: RecoveryHome, i: number) => {
                                                return <HomeCard key={item?.id} {...item} likeHandler={likeHandler} />;
                                            })}
                                        </div>  </div>

                                </>) : <Nohome title="No favorite homes yet!" />
                            }
                        </>
                    )
                }

            </section>

            {favoriteHomes.length > 0 && <Pagination handlePageChange={pageHandler} pageCount={data?.totalPages || 0} />}
        </>

    );
}

export default Favourites;
