import "./ConfirmPopup.css"
import alertsvg from "../../../assets/images/alert.svg"
import { useNavigate } from "react-router-dom";

const ConfirmPopup = ({setLogoutPopup , popupHandler}:{setLogoutPopup:React.Dispatch<React.SetStateAction<boolean>> , popupHandler:()=> void}) => {

   


    return (
        <>
            <div className="logout-main">
                <span ><img src={alertsvg} alt=""/></span>
                <h2>Please confirm!</h2>
                <p>Are you sure, you want to logout?</p>
                <div className="logout-btn">
                    <button className="no-btn" onClick={()=>setLogoutPopup(false)}>Cancel</button>
                    <button className="yes-btn" onClick={popupHandler}>Log out</button>
                </div>
            </div>
        </>
    );
}

export default ConfirmPopup;
