

import { configureStore } from "@reduxjs/toolkit";
import profile from "../slices/profile_slice/profile";
import authentication from "../slices/auth_slice/authentication";
import recoveryHomesSlice from "../slices/home_slice/recovery_homes";
import likedHome from "../slices/liked_home_slice/liked_home";
import filtersSlice from "../slices/filters/filters"

export const store = configureStore({
  reducer: {
    profile,
    authentication,
    recoveryHomesSlice,
    likedHome,
    filtersSlice
  },
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
