import React, { useEffect, useRef, useState } from 'react';
import Arrow from "../../../assets/images/angle_down_circle.svg";
import { textElips } from '../../../utils/common';

const CustomDropdown = ({ data, value, name, handleChange }: { data: any, value: string, name: string, handleChange: (a: string, b: string) => void }) => {
    const [toggle, setToggle] = useState(false)
    const [dropDownValue, setDropDownValue] = useState("--Select--")
    const dropdownRef = useRef<HTMLButtonElement | null>(null)

    useEffect(() => {
        if (value) {
            setDropDownValue(value)
        } else {
            setDropDownValue('--Select--')
        }
    }, [value])

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setToggle(false);
            }
        }
        document.addEventListener('click', handleClickOutside)
        return () => {
            document.removeEventListener('click', handleClickOutside)
        }

    }, [])

    const handleOnclick = (v: string) => {
        setDropDownValue(v)
        handleChange(name, v)
        setToggle(false); 
    }

    const handleButtonClick = () => {
        setToggle(!toggle);
    }

    return (
        <>
            <div className='filter_container'>
                <button ref={dropdownRef} className="filter-dropdown" onClick={handleButtonClick}>
                    {textElips(dropDownValue)}
                    <span>
                        <img src={Arrow} alt="icon not loaded"  />
                    </span>
                    {toggle && (
                        <div className="filter-dropdown-main">
                            <ul>
                                {data?.map((item: any) => (
                                    <li key={item} onClick={() => handleOnclick(item)}>
                                        {item}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </button>
            </div>
        </>
    );
}

export default CustomDropdown;
