import React from 'react';

const UnfillHeart = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 21" fill="none">
            <path d="M12.2257 19.7041C11.8694 19.8298 11.2825 19.8298 10.9262 19.7041C7.88699 18.6665 1.09595 14.3383 1.09595 7.0023C1.09595 3.76398 3.70547 1.14398 6.92283 1.14398C8.83019 1.14398 10.5175 2.06622 11.5759 3.4915C12.1144 2.76406 12.8157 2.17283 13.6237 1.76518C14.4318 1.35752 15.324 1.14478 16.2291 1.14398C19.4464 1.14398 22.0559 3.76398 22.0559 7.0023C22.0559 14.3383 15.2649 18.6665 12.2257 19.7041Z" stroke="#1980DA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}

export default UnfillHeart;
