import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { errorAlert, successAlert } from "../../../utils/toasts";
import { customerChangePassword, getUserInfo, updateUserInfo } from "../../../config/makeRequests/auth";
import { User } from "../../../types/authTypes";
import { Dispatch } from "@reduxjs/toolkit";


export const getProfileInfo = createAsyncThunk(
  "getProfileInfo/profileSlice",
  async () => {
    try {
      const userID = localStorage.getItem("userID")

      let res = await getUserInfo(userID)
      if (res.status === true) {
        return res?.data
      }

    } catch (error: any) {
      const { message } = error?.response?.data
      errorAlert(message)
      console.log("error while get getProfileInfo info ", error);
    }
  }
);
export const updateCustomerProfile = createAsyncThunk(
  "updateCustomerProfile/profileSlice",
  async (postData: any ,{dispatch}) => {
    try {
      const userID = localStorage.getItem("userID")
      let res = await updateUserInfo(userID, postData)
      if (res.status === true) {
        successAlert(res?.message)
        dispatch(getProfileInfo())
      }

      if (res.status === true) {
        return res?.data
      }

    } catch (error: any) {
      const { message } = error?.response?.data
      errorAlert(message)
      console.log("error while get updateCustomerProfile info ", error);
    }
  }
);
export const updateCustomerPassword = createAsyncThunk(
  "updateCustomerPassword/profileSlice",
  async ({ data, setPasswordPopup }: { data: any, setPasswordPopup: any }) => {
    try {
      const userID = localStorage.getItem("userID")
      let res = await customerChangePassword(userID, data)
      if (res.status === true) {
        successAlert(res?.message)
      }

      if (res.status === true) {
        setPasswordPopup(false)
        return res
      }

    } catch (error: any) {
      const { message } = error?.response?.data;
      console.log(error);

      errorAlert(message)
      console.log("error while get updateCustomerPassword info ", error);
    }
  }
);

interface State {
  isProfilePopUp: boolean,
  profileInfo: User | null,
  isLoading: boolean,
  isPasswordChanging: boolean
}

const initialState: State = {
  isProfilePopUp: false,
  profileInfo: null,
  isLoading: false,
  isPasswordChanging: false

};

const profileSlice = createSlice({
  name: "profileSlice",
  initialState,
  reducers: {
    profileVisibility: (state) => {
      state.isProfilePopUp = !state.isProfilePopUp
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getProfileInfo.fulfilled, (state, { payload }) => {
      if (payload) {
        state.profileInfo = payload
      }

    }).addCase(updateCustomerProfile.pending, (state, { payload }) => {
      state.isLoading = true

    }).addCase(updateCustomerProfile.fulfilled, (state, { payload }) => {
      state.isLoading = false
      state.isProfilePopUp = false


    }).addCase(updateCustomerProfile.rejected, (state, { payload }) => {
      state.isLoading = false

    }).addCase(updateCustomerPassword.pending, (state, { payload }) => {
      state.isPasswordChanging = true

    }).addCase(updateCustomerPassword.fulfilled, (state, { payload }) => {
      state.isPasswordChanging = false

    }).addCase(updateCustomerPassword.rejected, (state, { payload }) => {
      state.isPasswordChanging = false

    })
  }


});

export const { profileVisibility } = profileSlice.actions;
export default profileSlice.reducer;
