import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { customerLogin, customerSignupRequest, customerVerifyOtp, resendOtp } from "../../../config/makeRequests/auth";
import { InitialState } from "../../../components/auth/register/Register";
import { errorAlert, successAlert } from "../../../utils/toasts";
import { ClientApiResponse, ClientSignUpPostRequest } from "../../../types/authTypes";

interface customerType {
    data: InitialState;
    setOtpVerification: React.Dispatch<React.SetStateAction<boolean>>;
    action: any
}

export const customerSignup = createAsyncThunk(
    "signUpClient/authSlice",
    async ({ data, setOtpVerification, action }: customerType) => {
        try {

            const requestPayload = {
                // dialCode: data.dialCode,
                email: data?.email,
                phoneNumber: data?.phoneNumber.toString()
            }

            let res = await customerSignupRequest(requestPayload)
            if (res.status === true) {
                successAlert(res?.message);
                setOtpVerification(true);
                action.resetForm();
                const { phoneNumber, ...restData } = data || {};
                return { phoneNumber: data?.phoneNumber.toString(), ...restData };
            }

        } catch (error: any) {
            const { message } = error?.response?.data
            errorAlert(message)
            console.log("error while customerSignup ", error);
        }
    }
);

export const verifyCustomerOtp = createAsyncThunk(
    "verifyCustomerOtp/authSlice",
    async ({ payload, setOtpVerification , navigate}: { payload: any, setOtpVerification: React.Dispatch<React.SetStateAction<boolean>> , navigate:any}) => {
        try {

            let res = await customerVerifyOtp(payload)
            if (res.status === true) {
                successAlert(res?.message)
                setOtpVerification(false);
                localStorage.setItem("token", res?.token || "");
                localStorage.setItem("userID", res?.data?.id || "");
                navigate("/home");
                return res
            }

        } catch (error: any) {
            const { message } = error?.response?.data
            errorAlert(message)
            console.log("error while verifyCustomerOtp ", error);
        }
    }
);


export const customerLoginHandler = createAsyncThunk(
    "customerLoginHandler/authSlice",
    async ({ value, navigation, action }: { value: any, navigation: any, action: any }) => {
        try {

            let res = await customerLogin(value)
            if (res.status === true) {
                action.resetForm()
                successAlert(res?.message)
                localStorage.setItem("token", res?.token || "");
                    localStorage.setItem("userID", res?.data?.id || "");
                    navigation("/home");
                    return res;

            }

        } catch (error: any) {
            const { message } = error?.response?.data
            errorAlert(message)
            console.log("error while customerLoginHandler ", error);
        }
    }
);
export const resendOtpHandler = createAsyncThunk(
    "resendOtpHandler/authSlice",
    async ({ email }: { email: string }) => {
        try {

            let res = await resendOtp({ email })
            if (res.status === true) {
                successAlert(res?.message)
                return res
            }

        } catch (error: any) {
            const { message } = error?.response?.data
            errorAlert(message)
            console.log("error while get single Group info ", error);
        }
    }
);



interface StateType {
    isLoginScreen: boolean;
    clientData: InitialState | null | undefined;
    isLoading: boolean;
    otpLoader: boolean;
    customerInfo: ClientApiResponse | null | undefined;
    resendOtpLoader: boolean;
    isForgotPasswordScreen:boolean;
}


const initialState: StateType = {
    isLoginScreen: true,
    clientData: null,
    isLoading: false,
    otpLoader: false,
    customerInfo: null,
    resendOtpLoader: false,

    isForgotPasswordScreen:false
};

const authSlice = createSlice({
    name: "authSlice",
    initialState,
    reducers: {
        formHandler: (state, { payload }) => {
            state.isForgotPasswordScreen = false;
            state.isLoginScreen = payload
        },
        showForgotPasswordScreen: (state, { payload }) => {
            state.isForgotPasswordScreen = payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(customerSignup.pending, (state) => {
            state.isLoading = true;
        }).addCase(customerSignup.fulfilled, (state, { payload }) => {
            state.clientData = payload
            state.isLoading = false;
        }).addCase(customerSignup.rejected, (state, { payload }) => {
            state.isLoading = false
        }).addCase(verifyCustomerOtp.pending, (state, { payload }) => {
            state.otpLoader = true
        }).addCase(verifyCustomerOtp.fulfilled, (state, { payload }) => {
            state.customerInfo = payload
            state.otpLoader = false
            state.isLoginScreen = true
        }).addCase(verifyCustomerOtp.rejected, (state, { payload }) => {
            state.otpLoader = false
        }).addCase(customerLoginHandler.pending, (state, { payload }) => {
            state.isLoading = true
        }).addCase(customerLoginHandler.fulfilled, (state, { payload }) => {
            state.customerInfo = payload
            state.isLoading = false
        }).addCase(customerLoginHandler.rejected, (state, { payload }) => {
            state.isLoading = false
        }).addCase(resendOtpHandler.pending, (state, { payload }) => {
            state.resendOtpLoader = true
        }).addCase(resendOtpHandler.fulfilled, (state, { payload }) => {
            state.resendOtpLoader = false
        }).addCase(resendOtpHandler.rejected, (state, { payload }) => {
            state.resendOtpLoader = false
        })

    }
});

export const { formHandler  , showForgotPasswordScreen} = authSlice.actions;

export default authSlice.reducer;
