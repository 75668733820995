import React from 'react';
import "./footer.css"
import "../../../assets/css/responsive.css"
import FooterLogo from "../../../assets/images/footer_logo.png"
import Youtube_icon from "../../..//assets/images/youtube_icon.svg"
import Instagram_icon from "../../../assets/images/instagram_icon.svg"
import Facebook_icon from "../../../assets/images/facebook_icon.svg"
import Twitter_icon from "../../../assets/images/twitter_icon.svg"
import Footer_Mail_Icon from "../../../assets/images/footer_mail_icon.svg"
import { Link } from 'react-router-dom';
const Footer = () => {

    const documentUri ="https://narronline.org/wp-content/uploads/2016/12/NARR_levels_summary.pdf"
    return (
        <footer id="footer-main">
            <div className="footer-top-main">
                <div className="footer-container">
                    <div className="footer-top">
                        <div className="footer-left">
                            <a href="#"><img src={FooterLogo} alt="" /></a>
                            <p>Lighthouse Collective Foundation, Inc.
                                501(c)(3) Nonprofit Public Charity EIN
                                92-2429737
                            </p>
                            {/* <p className='recover'>Recover in Comfort, Find Your Home: Your Key to Stress-Free Rentals with Recovery Homefinder.</p> */}
                            {/* <ul>
                        <li><a href="#"><img src={Youtube_icon} alt="" /></a></li>
                        <li><a href="#"><img src={Instagram_icon} alt="" /></a></li>
                        <li><a href="#"><img src={Facebook_icon} alt="" /></a></li>
                        <li><a href="#"><img src={Twitter_icon} alt="" /></a></li>
                        <li><a href="#"><img src={Footer_Mail_Icon} alt="" /></a></li>
                    </ul> */}
                        </div>
                        <div className="footer-right">
                        <div>
                                <h2>Levels</h2>
                                <ul>
                                    <li><a href={documentUri} target="_blank">Document</a></li>
                                </ul>
                            </div>
                            <div>
                                <h2>Support</h2>
                                <ul>
                                    <li><Link to="/website-support">Help</Link></li>
                                    <li><Link to="/support">Support</Link></li>
                                </ul>
                            </div>
                            <div>
                                <h2>Legal</h2>
                                <ul>
                                    {/* <li><Link to="/term-conditions">Terms & Condition</Link></li> */}
                                    {/* <li><Link to="/">Terms & Conditions</Link></li> */}
                                    <li><Link to="/privacy">Privacy Policy</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <p>Copyright © 2024 Recovery Home Finder <sup>TM</sup></p>
            </div>
        </footer>
    );
}

export default Footer;
