import React from 'react';

const UnLikedSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="57" height="57" viewBox="0 0 57 57" fill="none">
    <circle cx="28.5" cy="28.5" r="28" fill="#E9F3FC" stroke="#C6E4FF"/>
    <path d="M29.2258 37.7041C28.8694 37.8298 28.2826 37.8298 27.9262 37.7041C24.887 36.6665 18.096 32.3383 18.096 25.0023C18.096 21.764 20.7055 19.144 23.9229 19.144C25.8302 19.144 27.5175 20.0662 28.576 21.4915C29.1144 20.7641 29.8158 20.1728 30.6238 19.7652C31.4318 19.3575 32.3241 19.1448 33.2291 19.144C36.4465 19.144 39.056 21.764 39.056 25.0023C39.056 32.3383 32.265 36.6665 29.2258 37.7041Z" stroke="#1980DA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

export default UnLikedSvg;
