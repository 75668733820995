import React from 'react';
import "./No_home.css"
import noHome from "../../../assets/images/No_home_img.svg"

const Nohome = ({title}:{title:string}) => {
    return (
      <>
         <div className='No_home'>
            <div className="container">
                <div className="blank_home">
                    <img src={noHome} alt='No home'/>
                    <p>{title}</p>
                </div>
            </div>
        </div>               
      </>
    );
  }

export default Nohome ;
