import React, { useEffect, useRef, useState } from 'react';
import Time_icon from "../../../assets/images/times_icon.svg";
import "./profile_popup.css";
import "../../../assets/css/responsive.css";
import { profileVisibility, updateCustomerProfile } from '../../../redux/slices/profile_slice/profile';
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks"
import ChangePasswordPopup from '../change_password/ChangePasswordPopup';
import UserLogo from '../../../assets/svg/UserLogo';
import CountryCode from '../../common/CountryCode';
import { warningAlert } from '../../../utils/toasts';
import Loader from '../../common/loader/Loader';
import ConfirmPopup from '../../common/Pop_Up/ConfirmPopup';
import { formHandler } from '../../../redux/slices/auth_slice/authentication';
import { useNavigate } from 'react-router-dom';
import unknownImg from '../../../assets/images/Unknown_profile.svg'
import { clearAllFilters } from '../../../redux/slices/filters/filters';


interface FormType {
    email: string;
    name: string;
    phoneNumber: {
        dialCode: string;
        number: string;
    };
    image: string;

}

const ProfilePopup = () => {
    const [formData, setFormData] = useState<FormType>({
        name: "",
        email: "",
        phoneNumber: {
            dialCode: "",
            number: ""
        },
        image: ""
    })
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [countryCode, setCountryCode] = useState("")
    const [newUserImage, setNewUserImage] = useState<any>(null)
    const [passwordPopup, setPasswordPopup] = useState(false)
    const [logoutPopup, setLogoutPopup] = useState(false)


    const navigate = useNavigate();




    const handleUploadClick = () => {
        fileInputRef.current?.click();
    };

    // Function to handle the file selection
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files?.[0];
        if (selectedFile) {
            setNewUserImage(selectedFile)
        }
    };


    useEffect(() => {
        setFormData((prevData) => ({
            ...prevData,
            phoneNumber: {
                ...prevData.phoneNumber,
                "dialCode": countryCode,
            },
        }));

    }, [countryCode])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        if (name === 'number') {
            setFormData((prevData) => ({
                ...prevData,
                phoneNumber: {
                    ...prevData.phoneNumber,
                    [name]: value,
                },
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };


    // Redux hooks
    const dispatch = useAppDispatch()
    const { isProfilePopUp, profileInfo, isLoading } = useAppSelector((state) => state.profile)


    useEffect(() => {
        if (profileInfo) {
            setFormData({
                name: profileInfo?.name,
                email: profileInfo?.email,
                phoneNumber: {
                    dialCode: profileInfo?.phoneNumber?.dialCode,
                    number: profileInfo?.phoneNumber?.number
                },
                image: profileInfo.image
            })
        }

    }, [profileInfo])


    function hanldeSaveData() {
        if (!formData.name || !formData.phoneNumber.number) return warningAlert("Required fields")
        const phoneRegex = /^\d{10}$/;
        const isPhoneNumberValid = phoneRegex.test(formData.phoneNumber.number);
        if(!isPhoneNumberValid) return warningAlert("Invalid phone number. ")
        
        let postData = new FormData()

        // Append fields to the FormData object
        postData.append("name", formData.name);
        postData.append("email", formData.email);
        // postData.append("dialCode", formData.phoneNumber.dialCode);
        postData.append("phoneNumber", formData.phoneNumber.number);
        postData.append("image", formData.image);
        if (newUserImage) {
            postData.append("newCustomerImage", newUserImage)

        }
        dispatch(updateCustomerProfile(postData))

    }

    const popupHandler = () => {
        dispatch(formHandler(true))
        localStorage.clear()
        dispatch(clearAllFilters())
        navigate("/")
        setLogoutPopup(false)
        dispatch(profileVisibility())
    }





    return (
        <>
            {passwordPopup && <ChangePasswordPopup setPasswordPopup={setPasswordPopup} />}
            {logoutPopup && <ConfirmPopup setLogoutPopup={setLogoutPopup} popupHandler={popupHandler} />}
            <div className={isProfilePopUp ? 'profile-overlay' : ''}>
                <div className={isProfilePopUp ? 'profile-main show-profile' : 'profile-main'}>
                    <div className="head">
                        <h2>Your Profile</h2>
                        <button onClick={() => dispatch(profileVisibility())}><img src={Time_icon} alt="" /></button>
                    </div>
                    <div className="details">
                        <div className="profile-upload">
                            <div className="profile-img">
                                {
                                    newUserImage ? <img src={URL.createObjectURL(newUserImage)} alt="image not available" /> : profileInfo?.image ? (<> <img src={profileInfo?.image} alt="user Profile" /></>) : <img src={unknownImg} width={'30px'} height={'30px'} alt="unknow Image" />
                                }
                            </div>
                            <span onClick={handleUploadClick}>Upload Photo <input type="file" ref={fileInputRef} name='newCustomerImage' onChange={handleFileChange} /> </span>
                        </div>
                        <div className="input-group">
                            <div className="input-field">
                                <label>Name</label>
                                <input
                                    type="text"
                                    placeholder="Your Name"
                                    value={formData?.name}
                                    name="name"
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="input-field">
                                <label>Email</label>
                                <input type="email" disabled onChange={handleChange} name="email" placeholder="abcde@gmail.com" value={formData?.email} />
                            </div>
                            <div className="input-field">
                                <label>Phone number</label>
                                <div className='dial-code'>
                                    {/* <CountryCode preFieldCode={profileInfo?.phoneNumber?.dialCode} setCountryCode={setCountryCode} /> */}
                                    <input type="text" onChange={handleChange} name="number" placeholder="+00 123456789" value={formData?.phoneNumber?.number} />
                                </div>
                            </div>
                            <div className="input-field">
                                <label>Password</label>
                                <div className="change-password">
                                    <input type="password" placeholder="xxxxxxxx25" value={"XXXXXXXXXXXXXXXXXX"} />
                                    <button onClick={() => setPasswordPopup(true)}>Change</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="profile-btn">
                        <button className="logout-btn" onClick={() => setLogoutPopup(!logoutPopup)}>Log out</button>
                        <button className="save-btn" disabled={isLoading} onClick={hanldeSaveData}>{isLoading && <Loader width={20} height={20} />} Save </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProfilePopup;
