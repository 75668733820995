import React from 'react';
import ReactPaginate from 'react-paginate';
import "./pagination.css";
import RightArrow from '../../../assets/svg/RightArrow';
import LeftArrow from '../../../assets/svg/LeftArrow';

const Pagination = ({handlePageChange , pageCount}:{handlePageChange:any , pageCount:number}) => {
  return (
    <div>
        <ReactPaginate
          breakLabel="..."
          nextLabel={<RightArrow/>}
          onPageChange={handlePageChange}
          // pageRangeDisplayed={5}
          pageCount={pageCount ?? 0}
          previousLabel={<LeftArrow/>}
          renderOnZeroPageCount={null}
          className="react_pagination"
          pageClassName="listStyle"
          activeClassName="active_page"
          activeLinkClassName="active_link"
          nextClassName="next_button_style"
          previousClassName="previous_button_style"
        />
      </div>
  );
}

export default Pagination;
