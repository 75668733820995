import * as  Yup from "yup"



export const Validation = Yup.object({
    email: Yup.string().email('Invalid email address').required("Email address is required"),
    phoneNumber: Yup.number()
        .typeError("That doesn't look like a phone number")
        .integer("A phone number can't include a decimal point")
        .test('len', 'Phone number must be exactly 10 digits',( val:any) => val && val.toString().length === 10)
        .required('Phone number is required'),
    password:Yup.string().min(5 , "Password must be at least 5 characters").required("Password is required"),
    name:Yup.string().required("Name is required")
})
export const loginValidation = Yup.object({
    email: Yup.string().email('Invalid email address').required("Email is required"),
    password:Yup.string().min(5 , "Password must be at least 5 characters").required("Password is required"),
})