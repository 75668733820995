import React from 'react';
import { Navigate } from 'react-router-dom';

const AuthRoutes = ({ children }: { children: any }) => {
    let token = localStorage.getItem("token")
    if (token) {
        return <Navigate to="/home"  replace={true}/>
    } else {
        return <>{children}</>
    }

}

export default AuthRoutes;
