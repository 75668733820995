import { Link, useNavigate } from "react-router-dom";
import bedIcon from "../../../assets/images/beds_icon.svg";
import LikedSvg from "../../../assets/svg/LikedSvg";
import UnLikedSvg from "../../../assets/svg/UnLikedSvg";
import { RecoveryHome } from "../../../types/homeTypes";
import "./homeCard.css";
import { textElips } from "../../../utils/common";


const HomeCard = ({ bedsAvailable, firstImage, guestHouseName, id, isLike, locationAddress, pricePerBedWeekly, images , likeHandler }: RecoveryHome) => {

    const navigate = useNavigate()
     //get Token from LocalStorage
     let token = localStorage.getItem("token") as unknown as boolean
const navigateHandler = ()=>{
    navigate(`/home-details/${id}`)
}

    return (
        // <Link to={`/home-details/${id}`}>
            <div className="filter-home-col" onClick={navigateHandler}>
                <div className="filter-home-main">
                    <span><img src={firstImage} height={300} alt="" /></span>
                    <div className="home-details">
                        <div className="home-price">
                            <div className="price">
                                <span>${pricePerBedWeekly} <span className="bed">/ bed</span></span>
                                <h3>{textElips(guestHouseName , 30)}</h3>
                            </div>
                            <button disabled={!token} onClick={(event:React.MouseEvent<HTMLButtonElement>)=>likeHandler(event , id , isLike )}>{isLike ? <LikedSvg /> : <UnLikedSvg />}</button>
                        </div>
                        <p>{locationAddress?.text}</p>
                        <div className="available-beds">
                            <span><img src={bedIcon} alt="image not found" />{bedsAvailable} Beds</span>
                        </div>
                    </div>
                </div>
                
            </div>
    //    </Link>
    );
}

export default HomeCard;
