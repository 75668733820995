import { deleteRequest, getRequest, getRequestWithoutAuth, postRequest, putRequest } from "../api"
import {HomeInfoIdResponseTypes, RecoveryHomesResponse , getFiltersResponseData} from "../../types/homeTypes"
import { GetRecoveryHomes } from "../../redux/types/recover_homes";


export const recoveryHomeRequest = (data:GetRecoveryHomes):Promise<RecoveryHomesResponse>=>{
    const { userId , currentPage,perPage ,  priceRangePerWeek, level, typeOfStructure , paymentType , transportOptions , adaComplaint , additionalDetails, gender , narrAccreditedOrCertified , medicationAssistedTreatment ,  long , lat  } = data;
    return getRequestWithoutAuth<RecoveryHomesResponse>(`customer/get-recovery-homes?page=${currentPage}&perPage=${perPage}&priceRangePerWeek=${priceRangePerWeek}&level=${level}&typeOfStructure=${typeOfStructure}&paymentType=${paymentType}&transportOptions=${transportOptions}&adaComplaint=${adaComplaint}&additionalDetails=${additionalDetails}&gender=${gender}&narrAccreditedOrCertified=${narrAccreditedOrCertified}&medicationAssistedTreatment=${medicationAssistedTreatment}&long=${long}&lat=${lat}`)
}
export const recoveryHomes = (data:GetRecoveryHomes):Promise<RecoveryHomesResponse>=>{
    const { userId , currentPage, perPage ,  priceRangePerWeek, level, typeOfStructure , paymentType , transportOptions , adaComplaint , additionalDetails, gender , narrAccreditedOrCertified , medicationAssistedTreatment , long , lat  } = data;
    return getRequest<RecoveryHomesResponse>(`customer/get-recovery-homes?page=${currentPage}&perPage=${perPage}&userId=${userId}&priceRangePerWeek=${priceRangePerWeek}&level=${level}&typeOfStructure=${typeOfStructure}&paymentType=${paymentType}&transportOptions=${transportOptions}&adaComplaint=${adaComplaint}&additionalDetails=${additionalDetails}&gender=${gender}&narrAccreditedOrCertified=${narrAccreditedOrCertified}&medicationAssistedTreatment=${medicationAssistedTreatment}&long=${long}&lat=${lat}`)
}
export const likedRecoveryHomesRequest = ({currentPage , userId , perPage}:{currentPage:number , userId:string , perPage:number}):Promise<RecoveryHomesResponse>=>{
    return getRequest<RecoveryHomesResponse>(`/customer/liked-recovery-homes?page=${currentPage}&userId=${userId}&perPage=${perPage}`)
}


interface likeRequestResponseData {
    message:string;
    status:boolean;
}
export interface likeRequestRequestData {
    homeId:string;
    userId:string;
}
export const addFavorite = (data:likeRequestRequestData):Promise<likeRequestResponseData>=>{
    return postRequest<likeRequestRequestData , likeRequestResponseData>(`/customer/add-favourite` , data)
}
export const removeFavorite = (userId:string , homeId:string)=>{
    return deleteRequest(`/customer/dislike-home?userId=${userId}&homeId=${homeId}` ,)
}
export const likeUnlikeHomeRequest = (likeHomeId:string , isLike:boolean):Promise<likeRequestResponseData>=>{
    return putRequest<likeRequestResponseData>(`/customer/like-unlike-recovery-home/${likeHomeId}?isLike=${isLike}`)
}

export const getFilters = ():Promise<getFiltersResponseData>=>{
    return getRequestWithoutAuth<getFiltersResponseData>(`/customer/get-misc-data`)
}

export const getHomeInfoById = ({id , userId}:{id:string , userId:string}):Promise<HomeInfoIdResponseTypes>=>{
    return getRequestWithoutAuth<HomeInfoIdResponseTypes>(`/customer/get-recovery-home/${id}`)
}
export const getCustomerHomeInfoById = ({id , userId}:{id:string , userId:string}):Promise<HomeInfoIdResponseTypes>=>{
    return getRequest<HomeInfoIdResponseTypes>(`/customer/recovery-home/${id}?userId=${userId}`)
}




export const getPlaceID = async (input:string)=>{
    return getRequest(`/placeId?input=${input}`)
}
export const getPlaceDetails= async (place_id :string)=>{
    return getRequest(`/place-details?place_id=${place_id }`)
}