import { useState } from "react";
import "./banner.css";
import "../../assets/css/responsive.css";
import Login from "../auth/login/Login";
import Register from "../auth/register/Register";
import { Link, useNavigate } from "react-router-dom";
import BannerImg from "../../assets/images/banner_img.png"
import VerifyOtp from "../common/verifyOtp/VerifyOtp";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { resendOtpHandler, showForgotPasswordScreen, verifyCustomerOtp } from "../../redux/slices/auth_slice/authentication";
import Forgotpassword from "../auth/forgot_password/Forgotpassword";


const Banner = () => {
  // const [isForgotScreen, setIsForgotScreen] = useState<boolean>(false);
  const [otpVerification, setOtpVerification] = useState<boolean>(false);
  const navigate = useNavigate()

  // Redux Hooks 
  const dispatch = useAppDispatch()
  const { isLoginScreen, clientData, otpLoader , isForgotPasswordScreen} = useAppSelector((state) => state.authentication)

  const otpSubmitHandler = (otp: string) => {
    const payload = {
      otp,
      ...clientData
    }
    dispatch(verifyCustomerOtp({ payload, setOtpVerification, navigate }))

  }


  const getNewOtp = () => {
    const email = clientData?.email;
    if (email) {
      dispatch(resendOtpHandler({ email }));
    }
  }

  const handleShowForgot = ()=>{
    dispatch(showForgotPasswordScreen(true))
  }

  return (
    <>
      {otpVerification &&
        <VerifyOtp
          data={clientData?.email}
          otpLoader={otpLoader}
          setOtpVerification={setOtpVerification}
          verifyHandler={otpSubmitHandler}
          getNewOtp={getNewOtp}
        />}

      <section id="banner-main">
        <div className="banner-img">
          <img src={BannerImg} alt="" />
        </div>
        <div className="banner-text-main">
          <div className="login-container">
            <div className={`banner-text ${isLoginScreen ? "login-banner" : "register-banner"}`}>
              <div className="banner-left">
                {!isLoginScreen && <span>JOIN FOR FREE</span>}
                <h1>Real-time access to recovery home availability</h1>
                <p>
                  - Your Premier Destination for Recovery Home Listings<br></br>
                  - Discover Real-Time Bed Availability in Recovery Homes <br></br>
                  - Direct Access to Recovery Home Providers
                </p>
                <h4>Mission and Activities</h4>
                <p className="light-house">Lighthouse Collective Foundation, Inc., a certified 501(c)(3) nonprofit, is dedicated to guiding individuals in need towards secure and nurturing housing options. Our mission centers on providing a beacon of hope for those seeking a stable environment and a sanctuary for healing. Through our website, we connect individuals with recovery homes that offer the support and stability needed for transformative healing.</p>
                <Link to="/home">Explore Homes</Link>
              </div>
              <div className="banner-right forgot-right">
                {
                  isForgotPasswordScreen &&  <Forgotpassword />  
                }
                {
                  !isForgotPasswordScreen && (
                    <>
                    {isLoginScreen ? (
                  <Login handleShowForgot={handleShowForgot} />
                ) : (
                  <Register setOtpVerification={setOtpVerification} />
                )}
                    </>
                  )
                }
                
                
              </div>
            </div>
          </div>
        </div>

      </section>
    </>
  );
};

export default Banner;
