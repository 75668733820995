import React, { useEffect, useRef, useState } from 'react';
import "../../../../assets/css/responsive.css";
import { getPlaceDetails, getPlaceID } from '../../../../config/makeRequests/homeRequests';
import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks';
import useDebounce from '../../../../hooks/UseDebounce';
import { filterBar } from '../../../../redux/slices/filters/filters';
import CustomDropdown from '../../../common/dropdown/CustomDropdown';
import Loader from '../../../common/loader/Loader';
import FilterPopUp from '../filters/FilterPopUp';
import "./filter_bar.css";
interface PropType {
    isFilterPopUpVisible: any

    setisFilterPopUpVisible: React.Dispatch<React.SetStateAction<boolean>>;
    filtersData: any
    searchHandler: () => void;
    isLoading: boolean;
    userLocation:string;
    locationDirection:{
        latitude:number;
        longitude:number;
    };
    setLocationDirection:any

}
const FilterBar = ({ isFilterPopUpVisible, setisFilterPopUpVisible, filtersData, searchHandler, isLoading , userLocation , locationDirection , setLocationDirection}: PropType) => {

    // let apiKey = process.env.REACT_APP_GOOGLE_KEY;
    // setKey(apiKey || "");

    // const [userLocation, setUserLocation] = useState('');
    const price = ["$0 - $100", "$101 - $200 ", "$201 +"]

    const [inputLocation , setInputLocation] = useState("")
    const debouncedInputLocation = useDebounce({ input: inputLocation, delay: 700 });
    const [addressSelected, setAddressSelected] = useState(false);
    const [loading, setIsLoading] = useState(false);
    const [dropDownVisible, setIsDropDownVisible] = useState(false);

    const [addressSuggestions , setAddressSuggestions] = useState([])

    const textAreaRef = useRef<any>(null);
    const liRef = useRef<any>(null);


    const dispatch = useAppDispatch()
    const { filterBarValues } = useAppSelector((state: { filtersSlice: any; }) => state.filtersSlice);


    useEffect(() => {
        if(userLocation){
        setInputLocation(userLocation)
        }
    }, [userLocation])

    useEffect(() => {
        const handleClickOutside = (event:any) => {
            const id = event.target.id
            if (!id) {
                // Clicked outside the textarea
                setIsDropDownVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [textAreaRef]);


    useEffect(() => {
        if (debouncedInputLocation && !addressSelected) {
            const getUserPlace = async ()=>{
                try {
                    setIsLoading(true)
                    let res:any = await getPlaceID(debouncedInputLocation)
                    if(res?.status){
                        setAddressSuggestions(res?.data)
                        setIsLoading(false)
                    } 
                } catch (error) {
                    console.log("error" , error)
                    setIsLoading(false)
                }
            }

            getUserPlace()
           
        }
       
    }, [debouncedInputLocation])


   

    const handleChange= (event:any)=>{
        const {value} = event?.target;
        setIsDropDownVisible(true)
        setInputLocation(value)
        setAddressSelected(false)
    }

    const handleAddress= async (value:any)=>{
        setInputLocation(value?.description)
        setAddressSelected(true);
        setAddressSuggestions([])
        try {
           
            const res:any = await getPlaceDetails(value?.place_id)
           
            if(res?.status){
                const data = await res?.data;
                setLocationDirection({...locationDirection , "latitude":data?.latitude , "longitude":data?.longitude})
              
                
            }
        } catch (error) {
            console.log("error" , error)
        }
    }

    const selectHandler = (name: string, value: string) => {
        const data = { [name]: value }
        dispatch(filterBar(data))
    }

    return (
        <div className="filter-bar-col">
            <div className="filter-bar-main">
                <div className="filter-bar">
                    <div className="filter">
                        <h3>Location</h3>
                        {/* <span>  */}
                            <textarea id="textAreaid" style={{resize:"none"}}  ref={textAreaRef}  value={inputLocation} onChange={handleChange} />

                            {
                             dropDownVisible && inputLocation?.length > 0 && (addressSuggestions?.length > 0 || loading ) &&  <div className='dropdownAddressList'>
                                <ul>
                                    
                                    {
                                        loading ?<div style={{display:"flex" , justifyContent:"center" , alignItems:"center" , height:"168px"}}> <Loader width={36} height={36}/> </div> : (
                                            <>
                                            {
                                                 addressSuggestions?.map((item:{description:string} , index)=>{
                                                    return <li id={`id${index}`} onClick={()=>handleAddress(item)}>{item?.description}</li>
                                                })
                                            }
                                            </>
                                        )
                                       
                                    }
                                     </ul>
                            </div>
                            }
                          
                             {/* </span> */}
                    </div>
                    <div className="filter">
                        <h3>Price / bed</h3>
                        <CustomDropdown data={price} name="price" value={filterBarValues.price} handleChange={selectHandler} />
                    </div>
                    <div className="filter">
                        <h3>Level</h3>
                        <CustomDropdown data={filtersData?.level} name="level" value={filterBarValues.level} handleChange={selectHandler} />

                    </div>
                    <div className="filter">

                        <h3>Type of Structure</h3>
                        <CustomDropdown data={filtersData?.typeOfStructure} value={filterBarValues.structure} name="structure" handleChange={selectHandler} />
                    </div>
                    <div className="filter btn">
                        <button className="search-btn" disabled={isLoading} onClick={searchHandler}>Search</button>
                    </div>
                </div>
                <button
                    className="add-filter"
                    onClick={() => setisFilterPopUpVisible(true)}
                >
                    ADD FILTERS
                </button>
                {isFilterPopUpVisible && (
                    <FilterPopUp setisFilterPopUpVisible={setisFilterPopUpVisible} filtersData={filtersData} locationDirection={locationDirection} />
                )}
            </div>
        </div>

    );
}

export default FilterBar;
