import { Route, Routes, useLocation } from "react-router-dom";
import { Suspense, useLayoutEffect } from "react";
import "./assets/css/style.css"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RouteList from "./layout/RouteList";


const App = () => {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);


  return (
       <Suspense fallback={null}>
				<RouteList />
        <ToastContainer />
			</Suspense>
  );
};

export default App;
