import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface FiltersState {
    gender: string[];
    paymentType: string[];
    additionalDetails: string[];
    transport: string[];
    radioOptions: {
        adaComplaint: string;
        narrCertified: string;
        matTreatment: string;
    };
    filterBarValues: {
        price: string,
        level: string,
        structure: string,
    }
}

const initialState: FiltersState = {
    gender: [],
    paymentType: [],
    additionalDetails: [],
    transport: [],
    radioOptions: {
        adaComplaint: '',
        narrCertified: '',
        matTreatment: '',
    },
    filterBarValues: {
        price: "",
        level: "",
        structure: "",
    }
};

const filtersSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        filterBar: (state, { payload }) => {
            state.filterBarValues = { ...state.filterBarValues, ...payload };
        },
        genderCheckboxes: (state, { payload }) => {
            let values = [...state.gender];
            if (values.includes(payload)) {
                const updatedValues = values.filter((val) => val !== payload);
                values = updatedValues;
            } else {
                values.push(payload);
            }
            state.gender = values;
        },
        paymentTypeCheckboxes: (state, { payload }) => {
            let values = [...state.paymentType];
            if (values.includes(payload)) {
                const updatedValues = values.filter((val) => val !== payload);
                values = updatedValues;
            } else {
                values.push(payload);
            }
            state.paymentType = values;
        },
        additionalDetailsCheckboxes: (state, { payload }) => {
            let values = [...state.additionalDetails];
            if (values.includes(payload)) {
                const updatedValues = values.filter((val) => val !== payload);
                values = updatedValues;
            } else {
                values.push(payload);
            }
            state.additionalDetails = values;
        },
        transportCheckboxes: (state, { payload }) => {
            let values = [...state.transport];
            if (values.includes(payload)) {
                const updatedValues = values.filter((val) => val !== payload);
                values = updatedValues;
            } else {
                values.push(payload);
            }
            state.transport = values;
        },
        updateRadioOptions: (state, { payload }) => {
            state.radioOptions = { ...state.radioOptions, ...payload };
        },
        clearAllFilters: (state) => {
            state.gender = [];
            state.paymentType = [];
            state.additionalDetails = [];
            state.transport = [];
            state.radioOptions = initialState.radioOptions;
            state.filterBarValues = {
                price: "",
                level: "",
                structure: "",
            }
        },
    },
});

export const {
    genderCheckboxes,
    paymentTypeCheckboxes,
    additionalDetailsCheckboxes,
    transportCheckboxes,
    updateRadioOptions,
    clearAllFilters,
    filterBar,
} = filtersSlice.actions;

export default filtersSlice.reducer;
