import { useEffect, useState } from "react";
import "../../assets/css/responsive.css";
import HomeCard from "../../components/common/card/HomeCard";
import FilterPopUp from "../../components/pages_components/home/filters/FilterPopUp";
import "./home.css";
// import Pagination from "../../components/common/pagination/Pagination";
import PageLoader from "../../components/common/loader/PageLoader";
import Pagination from "../../components/common/pagination/Pagination";
import SliderComponent from "../../components/common/slider/SliderComponent";
import FilterBar from "../../components/pages_components/home/filter_bar/FilterBar";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import useWindowSize from "../../hooks/useWindowSize";
import { customerFavoriteRequest, customerRemoveFavoriteRequest, getFiltersvalues, getRecoveryHomes } from "../../redux/slices/home_slice/recovery_homes";
import { RecoveryHome } from "../../types/homeTypes";
import Nohome from "../../components/common/no_homesfound_yet/No_home";
import FilterBtn from "../../assets/images/Filter_icon.svg";
import { replaceDollarSign } from "../../utils/common";
import { RxCross2 } from "react-icons/rx";
import {
  RequestType,
  geocode,
  setKey
} from "react-geocode";



const Home = () => {


  //Internal Storage of Component
  const [isFilterPopUpVisible, setIsFilterPopUpVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(12)
  const [isMobileFilter, setIsMobileFilter] = useState(false)
  const [userLocation, setUserLocation] = useState('');

  const [recoveryHomes, setRecoveryHomes] = useState<any>([]);
  const [locationDirection, setLocationDirection] = useState<{ latitude: number, longitude: number }>({
    latitude: 0, 
    longitude: 0, 
  });
  

  const isMobile = useWindowSize()

  // Redux Hooks 
  const dispatch = useAppDispatch();
  const { data, isLoading, filtersData } = useAppSelector((state: { recoveryHomesSlice: any; }) => state.recoveryHomesSlice);

  const { gender, paymentType, additionalDetails, transport, radioOptions, filterBarValues } = useAppSelector((state: { filtersSlice: any; }) => state.filtersSlice);

  let apiKey = process.env.REACT_APP_GOOGLE_KEY;
  setKey(apiKey || "");

  useEffect(() => {
    if (data) {
      setRecoveryHomes([...data?.recoveryHomes])
    }
  }, [data])



  useEffect(() => {
    if (isMobile) {
      setPerPage(4)
      setIsMobileFilter(false)
    } else {
      setPerPage(12)
      setIsMobileFilter(true)
    }
  
   
  }, [isMobile])

    // get current location of user 
    useEffect(() => {
      async function getUserLocation() {
          await navigator.geolocation.getCurrentPosition(async (position) => {
              const { latitude, longitude } = position.coords;
              try {
                if(position && position.coords){
                  setLocationDirection({...locationDirection , latitude, longitude})
                }
                  const res = await geocode(RequestType.LATLNG, `${latitude},${longitude}`)
                  if (res.status === 'OK' && res.results.length > 0) {
                      const formattedAddress = await res.results[0].formatted_address;
                      const location = formattedAddress.split(",")
                      const result = location[location.length - 3] + location[location.length - 2] + location[location.length - 1]
                      setUserLocation(result);
                  } else {
                      console.log('Address not found');
                  }
              } catch (error) {
                  console.log(error, "get location error ")
              }
          }, (error) => {
              console.log("error", error);
          });
      }

      getUserLocation();

  }, []);





  const fetchData = () => {

    let genderChecks = gender.join(",")
    let paymentTypeChecks = paymentType.join(",")
    let additionalDetailsChecks = additionalDetails.join(",");
    let transportOptions = transport.join(",");
    let adaComplaint = radioOptions.adaComplaint;
    let narrAccreditedOrCertified = radioOptions.narrCertified;
    let medicationAssistedTreatment = radioOptions.matTreatment;
    const { latitude, longitude } = locationDirection



    const requestData = {
      currentPage,
      perPage,
      priceRangePerWeek: replaceDollarSign(filterBarValues.price),
      level: filterBarValues.level,
      typeOfStructure: filterBarValues.structure,
      paymentType: paymentTypeChecks,
      transportOptions,
      adaComplaint,
      additionalDetails: additionalDetailsChecks,
      gender: genderChecks,
      narrAccreditedOrCertified,
      medicationAssistedTreatment,
      lat:latitude,
      long:longitude,
    };

    dispatch(getRecoveryHomes(requestData));
  };



  useEffect(() => {
    fetchData()
  }, [currentPage, perPage , locationDirection])


  useEffect(() => {
    dispatch(getFiltersvalues())
  }, [])


  const searchHandler = () => {
    const { price, level, structure } = filterBarValues;
    if (price || level || structure) {
      fetchData()
    }

  }

  const pageHandler = (e: any) => {
    setCurrentPage(e.selected + 1)
  }

  const handleFilter = () => {
    window.scrollTo(0, 0);
    setIsMobileFilter(!isMobileFilter)
  }

  // Home like handler 

  const likeHandler = (event: React.MouseEvent<HTMLButtonElement>, homeId: string, isLike: boolean) => {
    event.stopPropagation();
    if (isLike) {
      dispatch(customerRemoveFavoriteRequest(homeId))
    } else {
      dispatch(customerFavoriteRequest(homeId))
    }

    setRecoveryHomes((prevRecoveryHomes: any) => {
      const newData = prevRecoveryHomes.map((item: any) => {
        if (item?.id === homeId) {
          return { ...item, isLike: !item.isLike };
        }
        return item;
      });

      return newData;
    });


  };



  return (
    <>
      <section id="search-recovery-main">
        <div className="container">
          <div className="recoveryhead">
            <h2>Search for recovery homes</h2>
          </div>
         
          {
           isMobileFilter  && <FilterBar
              isFilterPopUpVisible={isFilterPopUpVisible}
              filtersData={filtersData}
              setisFilterPopUpVisible={setIsFilterPopUpVisible}
              searchHandler={searchHandler}
              isLoading={isLoading}
              userLocation={userLocation}
              locationDirection={locationDirection}
              setLocationDirection={setLocationDirection}
            />
          }
         
          {

            isLoading ? <PageLoader /> : (<>
              {
                recoveryHomes.length > 0 ? (
                  <>
                    <div className="row">
                      <div className="recoveryhead-col">
                      </div>
                      {recoveryHomes.map((item: RecoveryHome, i: number) => {
                        return <HomeCard key={item?.id} {...item} likeHandler={likeHandler} />;
                      })}
                    </div>
                  </>
                ) : <Nohome title="No homes found yet!" />
              }
            </>)

          }
        </div>

      </section>
      {
        isMobile && <div className="back_btn_mobile">
          <button onTouchStart={handleFilter}> {isMobileFilter ? <RxCross2 size={30} color="white"/> : <img src={FilterBtn} alt="Filter Button" /> } </button>
        </div>
      }

      {recoveryHomes.length > 0 ? <Pagination handlePageChange={pageHandler} pageCount={data?.totalPages || 0} /> : ""}


    </>
  );
};

export default Home;
