import "../../../assets/css/responsive.css";
import "./register.css"
import UserIcon from "../../../assets/images/user_icon.svg"
import mailIcon from "../../../assets/images/mail_icon.svg"
import phoneIcon from "../../../assets/images/phone_icon.svg"
import PasswordIcon from "../../../assets/images/password_hide_icon.svg"
import PasswordViewIcon from "../../../assets/images/eye-view.svg"
import { useFormik } from "formik";
import { Validation } from "../../../utils/form_validation_schemas";
import ErrorIndicator from "../../common/ErrorIndicator";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { customerSignup, formHandler } from "../../../redux/slices/auth_slice/authentication";
import { useState } from "react";
import Loader from "../../common/loader/Loader";
import CountryCode from "../../common/CountryCode";
import { LuEye, LuEyeOff } from "react-icons/lu";
import { warningAlert } from "../../../utils/toasts";

interface RegisterProps {
    setOtpVerification: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface InitialState {
    dialCode?: string;
    name: string;
    email: string;
    phoneNumber: string;
    password: string;
}
const initialValues: InitialState = {
    name: "",
    email: "",
    phoneNumber: "",
    password: "",
}

const Register = ({ setOtpVerification }: RegisterProps) => {

    // internal Storage of Component
    const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false)
    const [countryCode, setCountryCode] = useState("")

    // Redux Hooks 
    const dispatch = useAppDispatch()
    const { isLoading } = useAppSelector((state) => state.authentication)

    // form Handler
    const { values, touched, handleBlur, handleChange, handleSubmit, errors }: { values: InitialState, touched: any, handleBlur: any, handleChange: any, handleSubmit: any, errors: any } = useFormik<any>({
        initialValues,
        validationSchema: Validation,

        onSubmit: (value: any, action: any) => {
            const data = { dialCode: countryCode, ...value }
            const phoneRegex = /^\d{10}$/;
            const isPhoneNumberValid = phoneRegex.test(value.phoneNumber);
            if(!isPhoneNumberValid) return warningAlert("Invalid phone number. ")
            
            dispatch(customerSignup({ data, setOtpVerification , action }))
            
        }
    })


    return (
        <div className="register-main">
            <h2>Register</h2>
            <div className="input-field">
                <div className="input-left">
                    {/* <label>Name</label> */}
                    <input
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.name}
                        name="name"
                        placeholder="username"
                    />
                </div>
                <div className="input-right">
                    <span><img src={UserIcon} alt="" /></span>
                </div>
            </div>
            {touched.name && errors.name && <ErrorIndicator value={errors.name} />}
            <div className="input-field">
                <div className="input-left">
                    {/* <label>Email</label> */}
                    <input
                        type="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                        placeholder="example@domain.com"
                        name="email"

                    />
                </div>
                <div className="input-right">
                    <span><img src={mailIcon} alt="" /></span>
                </div>
            </div>
            {touched.email && errors.email && <ErrorIndicator value={errors.email} />}
            <div className="input-field">
                <div className="input-left">
                    {/* <label>Phone Number</label> */}
                    <div className="country_code_box">
                        {/* <CountryCode setCountryCode={setCountryCode} /> */}
                        <input
                            type="number"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.phoneNumber}
                            autoComplete="off"
                            name="phoneNumber"
                            placeholder="1234567890"
                        />
                    </div>
                </div>
                <div className="input-right">
                    <span><img src={phoneIcon} alt="" /></span>
                </div>
            </div>
            {touched.phoneNumber && errors.phoneNumber && <ErrorIndicator value={errors.phoneNumber} />}

            <div className="input-field">
                <div className="input-left">
                    {/* <label>Password</label> */}
                    <input className="password-place"
                        type={isPasswordVisible ? "text" : "password"}
                        autoComplete="off"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.password}
                        name="password"
                        placeholder="*************"
                    />

                </div>
                <div className="input-right">
                    <span onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
                    {isPasswordVisible?   <LuEye size={37}  /> : <LuEyeOff size={37} />}
                    </span>
                </div>
            </div>
            {touched.password && errors.password && <ErrorIndicator value={errors.password} />}

            <div className="already-member">
                <p>Already a member? <span onClick={() => dispatch(formHandler(true))}>Log In</span></p>
            </div>
            <div className="login-btn-main">
                <button className="login-btn" onClick={handleSubmit}>{isLoading && <Loader width={30} height={30}/> } Create account </button>
            </div>
        </div>
    );
}

export default Register;
