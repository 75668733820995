import { useEffect } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import "../../../assets/css/responsive.css";
import Logo from "../../../assets/images/Logo.png";
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { formHandler } from '../../../redux/slices/auth_slice/authentication';
import { getProfileInfo, profileVisibility } from '../../../redux/slices/profile_slice/profile';
import "./header.css";
import { CiHeart } from "react-icons/ci";
import { FaHeart } from "react-icons/fa";
import useWindowSize from '../../../hooks/useWindowSize';


const Header = () => {

    //redux Hooks
    const dispatch = useAppDispatch()
    const { profileInfo } = useAppSelector((state) => state.profile)
    const { isLoginScreen } = useAppSelector((state) => state.authentication)

    const location = useLocation()
    const isMobile = useWindowSize()

    const isFavoritePage = location.pathname === "/favorite"

    const token = localStorage.getItem("token")



    const getCustomerName = () => {
        const customerName = profileInfo?.name.split(" ")
        if (customerName && customerName?.length > 1) {

            return <span className='profile_name'>{customerName[0].substring(0, 1) + customerName[1].substring(0, 1)}</span>
        } else {
            return <span className='profile_name'>{customerName && customerName[0].substring(0, 1)}</span>
        }

    }

    useEffect(() => {
        if (token) {
            dispatch(getProfileInfo())
        }
    }, [token])

    const handleProfile = () => {
        dispatch(profileVisibility())
    }
    return (
        <header id="header-main">
            <div className="header">
                <div className="header-left">
                    <Link to="/home"><img src={Logo} alt="logo not found" /></Link>
                    {token && isMobile ? <NavLink className={({ isActive }) => (isActive ? 'Iconactive' : '')}
                        to="/favorite"
                    >
                        {isFavoritePage ? <FaHeart size={30} /> : <CiHeart size={30} />}
                    </NavLink> : token && <NavLink className={({ isActive }) => (isActive ? 'active' : '')} to="/favorite">Favorites</NavLink>}


                </div>
                <div className={`header-right ${token && 'header-right-profile'}`}>

                    {
                        token ? <div className="profile" onClick={handleProfile}>
                            {
                                profileInfo?.image ? <img className="profileImg" src={profileInfo?.image} alt="image not found " /> : (getCustomerName())
                            }

                        </div> : <div className="header-login-btn">
                            <Link to="/">  <button className={isLoginScreen ? "active-btn" : "login-btn"} onClick={() => dispatch(formHandler(true))}>Log in</button> </Link>
                            <Link to="/">  <button className={!isLoginScreen ? "active-btn" : ''} onClick={() => dispatch(formHandler(false))}>Sign up</button> </Link>
                        </div>
                    }

                </div>
            </div>
        </header>
    );
}

export default Header;
