import React, { useState } from 'react';
import Time_icon from "../../../assets/images/times_icon.svg";
import "./change_password.css";
import { warningAlert } from '../../../utils/toasts';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import Loader from '../../common/loader/Loader';
import { updateCustomerPassword } from '../../../redux/slices/profile_slice/profile';
import ViewEye from '../../../assets/svg/ViewEye';
import HideEye from '../../../assets/svg/HideEye';
import { LuEye, LuEyeOff } from 'react-icons/lu';

interface PropType {
    setPasswordPopup: React.Dispatch<React.SetStateAction<boolean>>
}

const ChangePasswordPopup = ({ setPasswordPopup }: PropType) => {
    const [passwordDetails, setPasswordDetails] = useState({
        currentPassword: "",
        newPassword: "",
        confirmPassword: ""
    });

    const [isCurrentPasswordVisible, setIsCurrentPasswordVisible] = useState<boolean>(false);
    const [isNewPasswordVisible, setIsNewPasswordVisible] = useState<boolean>(false);
    const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState<boolean>(false);

    // Redux hooks
    const dispatch = useAppDispatch()
    const { isPasswordChanging } = useAppSelector((state) => state.profile)

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setPasswordDetails({
            ...passwordDetails, [name]: value
        })
    }

    const handleEyeClick = (field: string) => {
        switch (field) {
            case 'currentPassword':
                setIsCurrentPasswordVisible(!isCurrentPasswordVisible);
                break;
            case 'newPassword':
                setIsNewPasswordVisible(!isNewPasswordVisible);
                break;
            case 'confirmPassword':
                setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
                break;
            default:
                break;
        }
    }

    const handleSubmit = () => {
        const { currentPassword, newPassword, confirmPassword } = passwordDetails;
        if (!currentPassword || !newPassword || !confirmPassword) return warningAlert("All Fields Required")
        const customerNewPassword = newPassword.toLowerCase().split('').sort().join('');
        const customerConfirmPassword = confirmPassword.toLowerCase().split('').sort().join('');
        if (customerNewPassword !== customerConfirmPassword) return warningAlert("New Password and Confirm Password must match");

        const data = {
            currentPassword,
            newPassword,
            confirmPassword
        }
        dispatch(updateCustomerPassword({data , setPasswordPopup}))
    }

    return (
        <div className='change-password-overlay'>
            <div className="change-password-main">
                <div className="change-head">
                    <h2>Change Password</h2>
                    <button onClick={() => setPasswordPopup(false)}><img src={Time_icon} alt="" /></button>
                </div>
                
                <div className="input-field">
                    <label>Current Password</label>
                    <div className="password">
                        <input type={isCurrentPasswordVisible ? "text" : "password"} onChange={handleChange} name="currentPassword" value={passwordDetails?.currentPassword} placeholder="**********" />
                        <span onClick={() => handleEyeClick('currentPassword')}>
                        {isCurrentPasswordVisible ?   <LuEye size={30}  /> : <LuEyeOff size={30} />}

                        
                        </span>
                    </div>
                </div>
                <div className="input-field">
                    <label>New Password</label>
                    <div className="password">
                        <input type={isNewPasswordVisible ? "text" : "password"} onChange={handleChange} name="newPassword" value={passwordDetails?.newPassword} placeholder="**********" />
                        <span onClick={() => handleEyeClick('newPassword')}>
                            {isNewPasswordVisible ?  <LuEye size={30}  /> : <LuEyeOff size={30} />} 
                        </span>
                    </div>
                </div>
                <div className="input-field">
                    <label>Confirm New Password</label>
                    <div className="password">
                        <input type={isConfirmPasswordVisible ? "text" : "password"} onChange={handleChange} name="confirmPassword" value={passwordDetails?.confirmPassword} placeholder="**********" />
                        <span onClick={() => handleEyeClick('confirmPassword')}>
                        {isConfirmPasswordVisible ?  <LuEye size={30}  /> : <LuEyeOff size={30} />} 
                        </span>
                    </div>
                </div>
                <div className="password-btn">
                    <button className="cancle-btn" onClick={() => setPasswordPopup(false)}>Cancel</button>
                    <button className="Save-btn" disabled={isPasswordChanging} onClick={handleSubmit}>{isPasswordChanging && <Loader height={20} width={20} />}Save</button>
                </div>
            </div>
        </div>
    );
}

export default ChangePasswordPopup;
