
import React from "react"

const Marketing = () =>{
    return(
        <section id="terms-main">
        <div class="privacy-container">
            <div class="row">
                <div class="terms-col">
                    <div class="terms-details">
                        <div class="our-term">
                            <h1>Recovery Home Finder Marketing URL Statement</h1>
                        </div>
                        <div class="terms-update">
                            <div class="first">
                                <h2>Discover Serenity and Support with Recovery Home Finder</h2>
                                <p>Embark on a path to renewal and healing with Recovery Home Finder, the premier mobile
                                    app designed to connect individuals and professionals with real-time recovery home
                                    listings. Seamlessly blending technology with compassion, our app offers an
                                    indispensable tool for those seeking a nurturing environment for recovery.
                                </p>
                            </div>

                            <div class="first">
                                <h2>Features and Benefits:</h2>
                                <ul>
                                    <li>Real-Time Listings: Access up-to-date information on recovery home vacancies in
                                        your chosen locale, ensuring you find a space that’s ready when you are.</li>
                                    <li> Geo-Specific Search: Our intuitive app allows for location-based searches,
                                        bringing you options right in your community or wherever you seek new
                                        beginnings.</li>
                                    <li> Direct Communication: Reach out to home managers efficiently, reducing wait
                                        times and facilitating a smoother transition into your chosen recovery space.
                                    </li>
                                </ul>
                            </div>

                            <div class="first">
                                <p><strong>User Endorsements:</strong> With testimonials from case managers, social
                                    workers, and former
                                    residents, Recovery Home Finder stands out as a trusted resource in the journey to
                                    sobriety and wellness.
                                </p>
                            </div>

                            <div class="first">
                                <p><strong>Engage with Us:</strong> Download Recovery Home Finder today and take the
                                    first step towards a
                                    place where peace meets purpose. For professionals, our platform promises a
                                    streamlined approach to finding patient accommodations that uphold the highest
                                    standards of care and comfort.</p>
                            </div>

                            <div class="first">
                                <p>Ready to Transform Lives? Join our community of supporters and seekers alike.
                                    Pre-register now to receive updates and be among the first to experience the way
                                    Recovery Home Finder is redefining the search for recovery homes.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    );
}

export default Marketing;