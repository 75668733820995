import { useState, useEffect } from 'react';


export default function useDebounce({ input, delay }:{input:string , delay:number}) {
  const [debounceValue, setDebounceValue] = useState(input);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebounceValue(input);
    }, delay);

    return () => clearTimeout(handler);
  }, [input]);

  return debounceValue;
}
