import React from 'react';

const RadioOption = ({handleOptionChange , name , label , option}:{handleOptionChange:(a: React.ChangeEvent<HTMLInputElement>)=>void , name:string , label:string , option:string}) => {
    return (
      <>
         <label>{label}</label>
                          <div className="input-select">
                              <div className="input-field">
                                  <input name={name} value={"Yes"} checked={option === "Yes"} type="radio" onChange={handleOptionChange}  />
                                  <label>Yes</label>
                              </div>
                              <div className="input-field">
                                  <input name={name} value="No" type="radio" checked={option === "No"} onChange={handleOptionChange} />
                                  <label>No</label>
                              </div>
                          </div>
      </>
    );
  }

export default RadioOption;
