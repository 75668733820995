import { ChangePasswordRequestData, ClientApiResponse, ClientLoginPostData, ClientPostRequestData, ClientPostResponseData, ClientSignUpPostRequest, ResendOtpRequest, ResendOtpResponse, } from "../../types/authTypes"
import { getRequest, postRequest, putFormDataRequest, putRequest } from "../api"




export const customerSignupRequest = (data:ClientPostRequestData): Promise<ClientPostResponseData> =>{
    return postRequest<ClientPostRequestData , ClientPostResponseData>("/customer/signup" , data)
}

export const customerVerifyOtp = (data:ClientSignUpPostRequest): Promise<ClientApiResponse> =>{
    return postRequest<ClientSignUpPostRequest , ClientApiResponse>("/customer/verify-otp-for-signup" , data)
}
export const customerLogin = (data:ClientLoginPostData): Promise<ClientApiResponse> =>{
    return postRequest<ClientLoginPostData , ClientApiResponse>("/customer/login" , data)
}



export const customerChangePassword = (id:string | null , data:ChangePasswordRequestData): Promise<ClientPostResponseData> =>{
    return putRequest<any>(`/customer/change-password/${id}` , data)
}

export const resendOtp = (data:ResendOtpRequest): Promise<ResendOtpResponse> =>{
    return postRequest<ResendOtpRequest , ResendOtpResponse>("/resend-otp" , data)
}
export const getUserInfo = (id:string | null): Promise<ClientApiResponse> =>{
    return getRequest<ClientApiResponse>(`/customer/get-customer-data/${id}`)
}


export const updateUserInfo = (id: string | null, data:any): Promise<any> => {
    return putFormDataRequest<any>(`/customer/update-customer-data/${id}`, data);
  };

export const forgotPassword = (data:{email:string}): Promise<any> => {
    return postRequest<any , any>(`/customer/forgot-password`, data);
  };

export const verifyCustomerOtp = (data:{email:string , otp:string}): Promise<any> => {
    return postRequest<any , any>(`/customer/verify-otp`, data);
  };
  
export const resetPassword = (data:{email:string , confirmPassword:string , newPassword:string}): Promise<any> => {
    return putRequest<any>(`/customer/reset-password`, data);
  };
  


