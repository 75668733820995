
const Privacy = () =>{
    return(
        <section id="terms-main">
        <div class="privacy-container">
            <div class="row">
                <div class="terms-col">
                    <div class="terms-details">
                        <div class="our-term">
                            <h1>Privacy Statement for Recovery Home Finder App</h1>
                        </div>
                        <div class="terms-update">
                            <div class="first">
                                <h2>1. Introduction</h2>
                                <p><strong>Purpose:</strong> This privacy statement outlines our commitment to
                                    protecting the privacy of our users.
                                    We understand the sensitive nature of the recovery journey and are dedicated to
                                    maintaining the confidentiality
                                    of your personal information.</p>
                            </div>

                            <div class="first">
                                <h2>2. Data Collection</h2>
                                <p><strong>Types of Data Collected:</strong> We only collect your name and phone number.
                                </p>
                                <p><strong>Method of Collection:</strong> This information is collected when you
                                    voluntarily
                                    provide it to us,
                                    typically during the registration process or when using certain features of the app.
                                </p>
                            </div>

                            <div class="first">
                                <h2>3. Use of Data</h2>
                                <p><strong>Purpose:</strong> Your name and phone number are used solely for the purpose
                                    of
                                    providing you with the
                                    services of our app, including facilitating communication and offering personalized
                                    assistance in finding
                                    recovery homes.</p>
                            </div>

                            <div class="first">
                                <h2>4. Data Sharing and Disclosure</h2>
                                <p><strong>Limited Sharing:</strong> We do not share your personal information with any
                                    third parties, except as
                                    necessary to provide you with the services offered by our app or as required by law.
                                </p>
                                <p><strong>Legal Obligations:</strong> In rare cases, we may be required to disclose
                                    your
                                    information in response to
                                    legal requests by public authorities.</p>
                            </div>

                            <div class="first">
                                <h2>5. Data Security</h2>
                                <p><strong>Protection Measures:</strong> We employ various security measures to protect
                                    your
                                    personal information
                                    from unauthorized access, alteration, disclosure, or destruction.</p>
                            </div>

                            <div class="first">
                                <h2>6. User Rights and Control</h2>
                                <p><strong>Access and Correction:</strong> You have the right to access and correct your
                                    personal information at any
                                    time.</p>
                                <p><strong>Consent and Withdrawal:</strong> Your use of the app constitutes consent to
                                    the
                                    collection and use of
                                    your name and phone number as outlined. You may withdraw your consent and request
                                    deletion of your data at any
                                    time.</p>
                            </div>

                            <div class="first">
                                <h2>7. Policy Updates</h2>
                                <p><strong>Notification of Changes:</strong> We will notify you of any changes to this
                                    privacy statement through the
                                    app or via other communication channels.</p>
                            </div>

                            <div class="first">
                                <h2>8. Contact Information</h2>
                                <p><strong>Inquiries:</strong> For any questions or concerns about our privacy
                                    practices,
                                    please contact us using
                                    the details provided in the app.</p>
                            </div>

                            <div class="first">
                                <h2>Final Notes</h2>
                                {/* <p><strong>Review:</strong> While this statement is crafted based on the information
                                    provided, it is advisable to
                                    have it reviewed by a legal professional to ensure compliance with applicable
                                    privacy
                                    laws.</p> */}
                                <p><strong>Transparency:</strong> We are committed to ensuring transparency in our data
                                    practices and encourage
                                    users to contact us with any privacy-related questions or concerns.</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    );
}

export default Privacy;