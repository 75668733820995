import React, { Fragment } from 'react';
import { Route, Routes } from 'react-router-dom';
import { baseRoutes } from './Routes';
import Layout from './Layout';
import Banner from '../components/banner/Banner';
import PrivacyPolicy from '../pages/privacy_policy/PrivacyPolicy';
import Favourites from '../pages/favourites/Favourites';
import Privacy from '../links/Privacy';
import Support from '../links/Support';
import Marketing from '../links/Marketing';
import AuthRoutes from '../private_routes/AuthRoutes';

const RouteList = () => {
  return (
    <Fragment>
      <Routes>
        {baseRoutes.map((route, i) => (
          <Route key={i} path={route.path} element={<Layout> {React.createElement(route.component)} </Layout>} />
        ))}
        <Route path="/" element={<Layout><AuthRoutes><Banner /></AuthRoutes></Layout>} />

        {/* below routes for mobile application's concern */}
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/support" element={<Support />} />
        <Route path="/marketing-url" element={<Marketing />} />
      </Routes>
    </Fragment>
  );
};

export default RouteList;
