
const Support = () =>{
    return(
        <section id="terms-main">
        <div class="privacy-container">
            <div class="row">
                <div class="terms-col">
                    <div class="terms-details">
                        <div class="our-term">
                            <h1>Recovery Home Finder Support URL language</h1>
                        </div>
                        <div class="terms-update">
                            <div class="first">
                                <h2>Recovery Home Finder Support</h2>
                                <p>Welcome to Recovery Home Finder’s support page, where our dedication to helping you
                                    navigate your recovery journey is unwavering. We understand that finding the right
                                    recovery home is a crucial step towards healing, and our support team is here to
                                    assist you with any queries or concerns you may have.
                                </p>
                            </div>

                            <div class="first">
                                <p>Whether you are a social worker, case manager, or an individual in need, our
                                    comprehensive support system ensures that you can easily find and secure a recovery
                                    home that meets your needs. Our app provides real-time bed availability, direct
                                    contact with home managers, and geographically specific searches to simplify your
                                    search.
                                </p>
                            </div>

                            <div class="first">
                                <p>For assistance, please refer to our tutorials and walkthroughs that guide you through
                                    the app’s features, ensuring you can maximize its potential with ease. Our friendly
                                    support team is also ready to respond to your inquiries with helpful and timely
                                    information. Plus, for issues that require more in-depth explanation, we offer media
                                    resources like screenshots and videos to better illustrate solutions..</p>
                            </div>

                            <div class="first">
                                <p>At Recovery Home Finder, your experience and feedback matter to us. Our support
                                    extends beyond troubleshooting; we’re here to listen, improve, and evolve our
                                    services to better meet your needs. Reach out to us anytime — we’re here to support
                                    your journey to recovery, 24/7.
                                </p>
                            </div>

                            <div class="first">
                                <p>For further support, contact us at 
                                 <a href="#"> Support@recoveryhomefinder.com</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
}
 
export default Support;
