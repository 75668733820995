import React from 'react';
import Loader from './Loader';
import "./loader.css";

const PageLoader = () => {
  return (
    <div className="loader_container"> <Loader width={36} height={36} /> </div>
  );
}

export default PageLoader;
