import Favourites from '../pages/favourites/Favourites';
import Home from '../pages/home/Home';
import HomeDetails from '../pages/home_details/HomeDetails';
import PrivacyPolicy from '../pages/privacy_policy/PrivacyPolicy';
import TermConditions from '../pages/terms_conditions/TermConditions';
import Privacy from '../links/Privacy';
import WebsiteSupport from '../pages/support/WebsiteSupport';
import SupportPage from '../pages/support/SupportPage';

// Public Routes
export const baseRoutes = [
    {
        name: 'Home',
        path: '/home',
        component: Home 
    },
    {
        name: 'Term Conditions',
        path: '/term-conditions',
        component: TermConditions 
    },
    {
        name: 'Privacy',
        path: '/privacy',
        component: PrivacyPolicy 
    },
    {
        name: 'Favorite',
        path: '/favorite',
        component: Favourites 
    },
    {
        name: 'Home Details',
        path: '/home-details/:id',
        component: HomeDetails 
    },
    {
        name: 'Support',
        path: '/website-support',
        component: WebsiteSupport 
    },
    {
        name: 'Support',
        path: '/support',
        component: SupportPage 
    }
];
