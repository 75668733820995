import axios from "axios";


// const API_URL = "https://zn2j5663-5052.inc1.devtunnels.ms/"
const API_URL = process.env.REACT_APP_API_URL

const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json'
    }
})

// Create a separate instance for requests without authorization
export const axiosWithoutAuth = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json'
    }
}); 


axiosInstance.interceptors.request.use(
    (config :any) => {
        let token = localStorage.getItem("token")
        if(token){
            config.headers["Authorization"] = `Bearer${token}`;
        }
        return config;
    },
    (error:any) => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response:any) => {
        return response.data;
    },
    (error:any) => {
        if (error.response && error.response.status === 401) {
            localStorage.clear()
            return Promise.reject({ status: 403 });
        }
        return Promise.reject(error);
    }
);



export const postRequest = <ReqData, ResData>( slug: string, data: ReqData
): Promise<ResData> => {
    return axiosInstance.post<ReqData, ResData>(slug, data)
}

export const getRequest = <ResData>(slug: string):Promise<ResData> => {
    return axiosInstance.get(slug)
}

export const deleteRequest = (slug: string) => {
    return axiosInstance.delete(slug)
}

export const putRequest = <ResponseData>(slug: string , data?:any):Promise<ResponseData> => {
    return axiosInstance.put(slug , data)
}

// Add a specialized function for FormData
export const putFormDataRequest = <ResData>(slug: string, formData: FormData): Promise<ResData> => {
    const formDataHeaders = {
      'Content-Type': 'multipart/form-data',
    };
  
    return axiosInstance.put(slug, formData, { headers: formDataHeaders });
  };

  // You can use this instance for requests that don't require authorization
export const getRequestWithoutAuth = async <ResData>(slug: string): Promise<ResData> => {
    let res = await axiosWithoutAuth.get(slug);
    return res?.data
};