import React, { useState } from 'react';
import "../../../assets/css/responsive.css";
import "./login.css";
import PasswordIcon from "../../../assets/images/password_hide_icon.svg"
import mailIcon from "../../../assets/images/mail_icon.svg"
import { useFormik } from 'formik';
import { loginValidation } from '../../../utils/form_validation_schemas';
import ErrorIndicator from '../../common/ErrorIndicator';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import Loader from '../../common/loader/Loader';
import { useNavigate } from 'react-router-dom';
import { customerLoginHandler, formHandler } from '../../../redux/slices/auth_slice/authentication';
import PasswordViewIcon from "../../../assets/images/eye-view.svg"
import { LuEyeOff } from "react-icons/lu";
import { LuEye } from "react-icons/lu";




interface LoginProps {
    setIsLoginScreen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface InitialState {
    email: string;
    password: string;
}

const initialValues = {
    email: "",
    password: ""
}

const Login = ({handleShowForgot}:{handleShowForgot:()=> void}) => {

    const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(true)

    //navigation hook
    const navigation = useNavigate()

    // Redux Hooks 
    const dispatch = useAppDispatch()
    const { isLoading } = useAppSelector((state) => state.authentication)


    // form Handler
    const { values, touched, handleBlur, handleChange, handleSubmit, errors }: { values: InitialState, touched: any, handleBlur: any, handleChange: any, handleSubmit: any, errors: any } = useFormik<any>({
        initialValues,
        validationSchema: loginValidation,

        onSubmit: (value:InitialState ,  action: any) => {
            dispatch(customerLoginHandler({value , navigation , action}))
        }
    })

    return (
        <div className="login-main">
            <h2>Log In</h2>
            <div className="input-field">
                <div className="input-left">
                    {/* <label>Email</label> */}
                    <input onBlur={handleBlur}
                        onChange={handleChange} 
                        value={values.email} 
                        name="email" 
                        type="email" 
                        autoComplete="off" 
                    placeholder="example@domain.com" />
                </div>
                <div className="input-right">
                    <span><img src={mailIcon} alt="" /></span>
                </div>
            </div>
            {touched.email && errors.email && <ErrorIndicator value={errors.email} />}
            <div className="input-field">
                <div className="input-left">
                    {/* <label>Password</label> */}
                    <input className='password-place' onBlur={handleBlur}
                        onChange={handleChange} value={values.password} name="password" autoComplete="off" type={isPasswordVisible ? "password" : "text"} placeholder="*************" />
                </div>
                <div className="input-right">
                    <span onClick={() => setIsPasswordVisible(!isPasswordVisible)}>{isPasswordVisible? <LuEyeOff size={37} /> : <LuEye size={37}  />}</span>
                </div>
            </div>
            {touched.password && errors.password && <ErrorIndicator value={errors.password} />}
            <div className="forgot-password">
                <a onClick={handleShowForgot}>Forgot Password?</a>
            </div>
            <div className="login-btn-main">
                <button className="login-btn" disabled={isLoading} onClick={handleSubmit}> {isLoading ? <Loader width={36} height={36}/> : "Log in" } </button>
            </div>
            <div className="new-member">
                <p>New member? <span onClick={() => dispatch(formHandler(false))} className='sign_Up_btn'>Sign up</span></p>
            </div>
        </div>
    );
}

export default Login;
