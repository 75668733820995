import React from 'react';

const LikedSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="57" height="57" viewBox="0 0 57 57" fill="none">
            <circle cx="28.5" cy="28.5" r="28" fill="#E9F3FC" stroke="#C6E4FF" />
            <path d="M39.5785 25.6597C39.5785 32.5367 29.382 38.1031 28.9477 38.333C28.8333 38.3946 28.7053 38.4268 28.5754 38.4268C28.4454 38.4268 28.3175 38.3946 28.2031 38.333C27.7688 38.1031 17.5723 32.5367 17.5723 25.6597C17.5741 24.0449 18.2164 22.4966 19.3583 21.3548C20.5002 20.2129 22.0484 19.5705 23.6633 19.5687C25.692 19.5687 27.4682 20.4411 28.5754 21.9157C29.6826 20.4411 31.4588 19.5687 33.4875 19.5687C35.1024 19.5705 36.6506 20.2129 37.7925 21.3548C38.9344 22.4966 39.5767 24.0449 39.5785 25.6597Z" fill="#1980DA" />
        </svg>
    );
}

export default LikedSvg;
