import React from "react";
import Header from "../components/common/header/Header";
import Footer from "../components/common/footer/Footer";
import ProfilePopup from "../components/profile/profile_popup/ProfilePopup";

const Layout = ({ children }: any) => {

  return (
    <>
      <Header />
      {children}
      <Footer />
      <ProfilePopup />
    </>
  );
};

export default Layout;
