/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useRef, useState } from 'react';
import "../../../../assets/css/responsive.css"
import "./filter.css";
import Times_icon from "../../../../assets/images/times_icon.svg";
import RadioOption from '../../../RadioOption';
import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks';
import { getRecoveryHomes } from '../../../../redux/slices/home_slice/recovery_homes';
import {additionalDetailsCheckboxes, clearAllFilters, genderCheckboxes , paymentTypeCheckboxes, transportCheckboxes, updateRadioOptions} from "../../../../redux/slices/filters/filters"
import { replaceDollarSign } from '../../../../utils/common';
import useWindowSize from '../../../../hooks/useWindowSize';

interface PropType {
    setisFilterPopUpVisible: React.Dispatch<React.SetStateAction<boolean>>;
    filtersData: any;
    locationDirection:{
        latitude:number;
        longitude:number;
    }
}

const FilterPopUp = ({ setisFilterPopUpVisible, filtersData  , locationDirection}: PropType) => {

  const [perPage, setPerPage] = useState<number>(12)
   
  const isMobile = useWindowSize()

  useEffect(() => {
    if (isMobile) {
      setPerPage(4)
    }else{
      setPerPage(12)
    }
  }, [isMobile])

     // Redux Hooks 
     const dispatch = useAppDispatch();
     const { gender , paymentType , filterBarValues , additionalDetails , transport , radioOptions } = useAppSelector((state) => state.filtersSlice)

    const handleCheckboxChange = (value: string, index: number, type: string) => {

        // Update the copy based on the checkbox type
        switch (type) {
            case 'Gender':
                dispatch(genderCheckboxes(value))
              
                break;
            case 'Payment Type':
                dispatch(paymentTypeCheckboxes(value))
              
                break;
            case 'Additional Details':
                dispatch(additionalDetailsCheckboxes(value))
                break;
            case 'Transportation':
                dispatch(transportCheckboxes(value))
              
                break;
            default:
                break;
        }
    };

   

    const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        dispatch(updateRadioOptions({ [name]: value }));
      };



    const renderFilter = (filterType: string, filterData: string[] = [], checkboxesState: string[]) => {
        return (<div className="filter">
            <label>{filterType}</label>
            <div className="input-select">
                {filterData.map((data: string, index: number) => (
                    <div key={index} className="input-field">
                        <input type="checkbox" checked={checkboxesState.includes(data)} value={data} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCheckboxChange(e.target.value, index, filterType)} />
                        <label>{data}</label>
                    </div>
                ))}
            </div>
        </div>
        )
    }

    const handleClearFilter = () => {
        dispatch(clearAllFilters())
       
    }


    const applyHandler = () => {
        let genderChecks = gender.join(",")
        let paymentTypeChecks = paymentType.join(",")
        let additionalDetailsChecks = additionalDetails.join(",");
        let transportOptions = transport.join(",");
        let adaComplaint = radioOptions.adaComplaint;
        let narrAccreditedOrCertified = radioOptions.narrCertified;
        let medicationAssistedTreatment = radioOptions.matTreatment;
        const { latitude, longitude } = locationDirection
        const requestData = {
            currentPage: 1,
            perPage,
            priceRangePerWeek: replaceDollarSign(filterBarValues.price),
            level:filterBarValues.level,
            typeOfStructure: filterBarValues.structure,
            paymentType:paymentTypeChecks,
            transportOptions,
            adaComplaint,
            additionalDetails:additionalDetailsChecks,
            gender:genderChecks,
            narrAccreditedOrCertified,
            medicationAssistedTreatment,
            lat:latitude,
            long:longitude
        };
        if (paymentType || transportOptions || adaComplaint || additionalDetails || gender || narrAccreditedOrCertified || medicationAssistedTreatment) {
            dispatch(getRecoveryHomes(requestData));
            setisFilterPopUpVisible(false)
        }

    }

    return (
        <>
            <div className="overlay"></div>
            <div className="filter-main filter-active">
                <div className="head">
                    <h2>Filters</h2>
                    <button onClick={() => setisFilterPopUpVisible(false)}><img src={Times_icon} alt="" /></button>
                </div>
                <div className="filter-select">
                    {renderFilter("Gender", filtersData?.gender, gender)}
                    {renderFilter('Payment Type', filtersData?.paymentTypes, paymentType)}
                    {renderFilter('Additional Details', filtersData?.additionalDetails, additionalDetails)}
                </div>
                <div className="filter-select filter-select-two">
                    <div className="filter filter-addition">
                        <label>Transportation Options</label>

                        <div className="input-select">
                            {
                                filtersData?.transportationOptions.map((_transportData: string, _i: number) => {
                                    return (
                                        <div key={_i} className="input-field">
                                            <input type="checkbox" checked={transport.includes(_transportData)} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCheckboxChange(e.target.value, _i, "Transportation")} value={_transportData} />
                                            <label>{_transportData}</label>
                                        </div>
                                    )
                                })
                            }

                        </div>
                    </div>
                    <div className="filter filter-Ada">
                        <div className="filter-first">
                            <RadioOption
                                handleOptionChange={handleOptionChange}
                                name="adaComplaint" label="ADA Compliant"
                                option={radioOptions?.adaComplaint}
                            />

                        </div>
                        <div className="filter-two">
                            <RadioOption
                                handleOptionChange={handleOptionChange}
                                name="narrCertified"
                                label="NARR Accredited or Certified"
                                option={radioOptions?.narrCertified}
                            />

                        </div>
                    </div>
                    <div className="filter">
                        <RadioOption
                            handleOptionChange={handleOptionChange}
                            name="matTreatment"
                            label="MAT (Medication-assisted treatment)"
                            option={radioOptions?.matTreatment}
                        />

                    </div>
                </div>
                <div className="filter-btn">
                    <button className="clear-btn" onClick={handleClearFilter}>Clear All</button>
                    <button className="apply-btn" onClick={applyHandler}>Apply</button>
                </div>
            </div>


        </>

    );
}

export default FilterPopUp;