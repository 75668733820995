import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RecoveryHomeState } from "../../../types/homeTypes";
import { likedRecoveryHomesRequest } from "../../../config/makeRequests/homeRequests";

export const getLikedHomes = createAsyncThunk(
    "getLikedHomes/likedHome",
    async ({ currentPage , perPage }: { currentPage: number , perPage:number} , {rejectWithValue}) => {
        try {
            const userId = localStorage.getItem("userID") as string

            let res = await likedRecoveryHomesRequest({currentPage , userId , perPage})
            if (res.status === true) {
                return res?.data
            }

        } catch (error: any) {
            const { message, data } = error?.response?.data;
            return rejectWithValue({ errorMessage: message, errorData: data });
        }
    }
);



interface StateType {
    data: RecoveryHomeState | null | undefined;
    isLoading: boolean;
    error: { errorMessage: string; errorData: any } | null;
}


const initialState: StateType = {
    data: null,
    isLoading: false,
    error: null,
};

const likedHome = createSlice({
    name: "likedHome",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getLikedHomes.pending, (state: StateType) => {
            state.isLoading = true;
        }).addCase(getLikedHomes.fulfilled, (state: StateType, { payload }) => {
            state.data = payload
            state.isLoading = false;
        }).addCase(getLikedHomes.rejected, (state: StateType, { payload }) => {
            if(payload){
                state.error = payload as any
                state.isLoading = false
            }
            
        })

    }
});


export default likedHome.reducer;
