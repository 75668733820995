import React from 'react';
import Support from '../../links/Support';

const WebsiteSupport = () => {
  return (
    <>
      <Support/>
    </>
  );
}

export default WebsiteSupport;
